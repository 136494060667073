import React, { useState } from 'react';

import { Formik } from 'formik';
import { Button, Checkbox, Grid, Responsive } from 'semantic-ui-react';

import styled from 'styled-components';

import * as Yup from 'yup';

import Card from '../../containers/Card';
import Loader from '../../components/Loader';

import BusinessFields from './BusinessFields';
import BankAccountFields from './BankAccountFields';
import AddressFields from './AddressFields';
import FileUploadFields from './FileUploadFields';
import ModalTermsOfService from './ModalTermsOfService';

import { setData } from '../../services/api/tenants';
import { sendDocument } from '../../services/api/uploadFile';
import { dangerNotification } from '../../services/notification';

import { CEP_FORMAT, CPF_FORMAT, CNPJ_FORMAT, PHONE_FORMAT } from '../../services/masks';

const BottomContainer = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const initialBusinessValues = {
  type:'business',
  description: '',
  name: '',
  document: '',
  phone: '',
  email: '',
  covenant: '',
  covenant_other: '',
  owner: {
    first_name: '',
    last_name: '',
    document: '',
    phone: '',
    email: ''
  },
  address: {
    postal_code: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: ''
  },
  bank_account: {
    bank_code: '',
    routing_number: '',
    routing_number_digit: '',
    account_number: '',
    account_number_digit: '',
    document: '',
    holder_name: ''
  },
  filename: ''
};

const validationBusinessSchema =Yup.object().shape({
  description: Yup.string()
    .required('A Razão Social é obrigatória'),
  name: Yup.string()
    .required('O Nome Fantasia é obrigatório'),
  document: Yup.string()
    .matches(CNPJ_FORMAT, 'CNPJ inválido')
    .required('O CNPJ é obrigatório'),
  phone: Yup.string()
    .matches(PHONE_FORMAT, 'Telefone inválido')
    .required('O telefone é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O e-mail é obrigatório'),
  covenant: Yup.string()
    .required('A instituição é obrigatória'),
  covenant_other: Yup.string()
    .when('covenant', {
      is: value => value === 'Outros',
      then: Yup.string()
        .required('A instituição é obrigatória'),
      otherwise: Yup.string()
    }),
  owner: Yup.object().shape({
    first_name: Yup.string()
      .required('O nome do proprietário é obrigatório'),
    last_name: Yup.string()
      .required('O sobrenome do proprietário é obrigatório'),
    document: Yup.string()
      .matches(CPF_FORMAT, 'CPF inválido')
      .required('O CPF do proprietário é obrigatório'),
    phone: Yup.string()
      .matches(PHONE_FORMAT, 'Telefone inválido')
      .required('O telefone do proprietário é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('O e-mail do proprietário é obrigatório')
  }),
  address: Yup.object().shape({
    postal_code: Yup.string()
      .matches(CEP_FORMAT, 'CEP inválido')
      .required('O CEP é obrigatório'),
    street: Yup.string()
      .required('O logradouro é obrigatório'),
    number: Yup.string()
      .required('O número é obrigatório'),
    complement: Yup.string(),
    district: Yup.string()
      .required('O bairro é obrigatório'),
    city: Yup.string()
      .required('A cidade é obrigatória'),
    state: Yup.string()
      .required('O estado é obrigatório')
  }),
  bank_account: Yup.object().shape({
    bank_code: Yup.string()
      .required('O Banco é obrigatório'),
    routing_number: Yup.string()
      .required('A agência é obrigatória')
      .min(4, 'A agência deve ter no mínimo 4 números'),
    routing_number_digit: Yup.string()
      .length(1, 'O dígito da conta deve ter 1 número'),
    account_number: Yup.string()
      .required('A conta é obrigatória')
      .min(5, 'A conta deve ter no mínimo 5 números'),
    account_number_digit: Yup.string()
      .length(1, 'O dígito da conta deve ter 1 número')
  }),
  filename: Yup.string()
    .required('O arquivo de documento é obrigatório')
});

const BusinessForm = (props) => {
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (e, state) => {
    setAgreement(state.checked);
  };

  return (
    <>
      { loading && ( <Loader /> ) }
      <Formik initialValues={initialBusinessValues}
        enableReinitialize
        validationSchema={validationBusinessSchema}
        onSubmit={async (form) => {
          try {
            const values = Object.assign({}, form);

            setLoading(true);

            const client = await setData(values, process.env.REACT_APP_DEFAULT_OWNER);
            await sendDocument(values.filename, client, process.env.REACT_APP_DEFAULT_OWNER);

            setLoading(false);
            props.onFinish({ ...props, name: values.description });
          } catch (e) {
            dangerNotification('Atenção', 'Ocorreu um erro na confirmação do cadastro');
            setLoading(false);
          }
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Responsive fireOnMount as={Card}>
              <BusinessFields {...props} />
              <AddressFields {...props} />
              <BankAccountFields {...props} />
              <FileUploadFields {...props} />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <BottomContainer>
                      <Checkbox name="agreement" label="Eu li e concordo" onChange={onChange} />
                      <ModalTermsOfService
                        textBegin=" com os"
                        textLink=" termos de uso e política de privacidade"
                        textEnd=" do CartExpress"
                      />
                      <Button primary type="submit" floated="right" disabled={!agreement}>Concluir credenciamento</Button>
                    </BottomContainer>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Responsive>
          </form>
        )}
      </Formik>
    </>
  );
};

export default BusinessForm;
