import React from 'react';

import { ErrorMessage, Field } from 'formik';
import { Divider, Grid, Header } from 'semantic-ui-react';

import styled from 'styled-components';

import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';

import { covenants } from '../../services/covenants';
import { maskCpf, maskCnpj, maskPhoneNumber } from '../../services/masks';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
`;

const BusinessFields = (props) => {
  const { setFieldValue } = props;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            Dados da empresa
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>Razão Social</label>
          <Field name="description" type="text" component={Input} fluid
            onChange={(e) => {
              setFieldValue('description', e.target.value);
              setFieldValue('bank_account.holder_name', e.target.value);
            }}
          />
          <Error name="description" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>Nome Fantasia</label>
          <Field name="name" type="text" component={Input} fluid />
          <Error name="name" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <label>CNPJ</label>
          <Field name="document" type="text" maxLength="18" component={Input} fluid
            onChange={(e) => {
              setFieldValue('document', maskCnpj(e.target.value));
              setFieldValue('bank_account.document', maskCnpj(e.target.value));
            }}
          />
          <Error name="document" component="div" />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Telefone</label>
          <Field name="phone" type="text" maxLength="15" component={Input} fluid
            onChange={(e) => {
              setFieldValue('phone', maskPhoneNumber(e.target.value));
            }}
          />
          <Error name="phone" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>E-Mail</label>
          <Field name="email" type="text" component={Input} fluid />
          <Error name="email" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>Instituição Associada</label>
          <Field name="covenant" type="text" component={Dropdown} options={covenants} fluid />
          <Error name="covenant" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>Insira a instituição associada</label>
          <Field name="covenant_other" type="text" component={Input} disabled={props.values.covenant !== 'Outros'} fluid />
          <Error name="covenant_other" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            Dados pessoais
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>Nome do oficial</label>
          <Field name="owner.first_name" type="text" component={Input} fluid />
          <Error name="owner.first_name" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>Sobrenome</label>
          <Field name="owner.last_name" type="text" component={Input} fluid />
          <Error name="owner.last_name" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <label>CPF</label>
          <Field name="owner.document" type="text" maxLength="14" component={Input} fluid
            onChange={(e) => setFieldValue('owner.document', maskCpf(e.target.value))}
          />
          <Error name="owner.document" component="div" />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Telefone</label>
          <Field name="owner.phone" type="text" maxLength="15" component={Input} fluid
            onChange={(e) => {
              setFieldValue('owner.phone', maskPhoneNumber(e.target.value));
            }}
          />
          <Error name="owner.phone" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>E-Mail</label>
          <Field name="owner.email" type="text" component={Input} fluid />
          <Error name="owner.email" component="div" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BusinessFields;
