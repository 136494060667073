import * as Api from './api';

export const list = async (tenantId, ownerId, limit, query) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`,
  };

  const response = await Api.doRequest(
    `/owners/${ownerId}/tenants/${tenantId}/sales/${limit}${query ? query : ''}`,
    'GET',
    headers
  );

  return response.data.data;
};

export const updateList = async (tenantId, ownerId, limit, offset, query) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`,
  };

  const response = await Api.doRequest(
    `/owners/${ownerId}/tenants/${tenantId}/nextSales/${limit}/${offset}${query ? query : ''}`,
    'GET',
    headers
  );

  return response.data.data;
};

export const save = async (tenantId, ownerId, saleData) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`,
  };

  const response = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/sales`, 'POST', headers, saleData);

  return response.data;
};

export const deleteSale = async (tenantId, ownerId, sale) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`,
  };

  const response = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/sale/${sale}`, 'GET', headers);

  return response.data;
};

export const saleByToken = async (token) => {
  const response = await Api.doRequest(`/checkout/?token=${token}`, 'GET');
  return response.data;
};

export const checkoutPayment = async (request) => {
  const response = await Api.doRequest(`/checkout/?token=${request.token}`, 'POST', undefined, request);
  return response.data;
};
