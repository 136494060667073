import React from 'react';

import { Grid, Responsive, Message, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Card from '../../containers/Card';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const StyleParagraph = styled.p`
  word-break: break-word;
  line-height: 22px;
  padding-top: 35px;
  @media(max-width: 1000px) {
    padding-top: 0px;
  }
`;

const EqualDivider = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media(max-width: 1000px) {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyleImage = styled.svg`
  width: 30%;
  font-size: 6rem;
`;

const DescriptionContainer = styled.div`flex:2`;

export const DocumentDeclined = () =>
  <>
    <Responsive>
      <Grid centered columns={2}>
        <Grid.Row>
          <Grid.Column columns={1} width={10}>
            <Responsive as={Card}>
              <Grid stackable columns={2}>
                <EqualDivider>
                  <IconContainer>
                    <StyleImage>
                      <ReportProblemOutlinedIcon fontSize="large" />
                    </StyleImage>
                  </IconContainer>
                  <DescriptionContainer>
                    <Message.Content>
                      <StyleParagraph>
                        Ops! Encontramos uma inconsistência no seu cadastro.<br />
                        Aguarde o nosso contato.
                      </StyleParagraph>
                    </Message.Content>
                  </DescriptionContainer>
                </EqualDivider>
              </Grid>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column columns={1} width={10}>
            <Link to="/">
              <Button type="button" color="black" fluid>Sair</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Responsive>
  </>

export default DocumentDeclined;
