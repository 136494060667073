import React from 'react';

import { Segment } from 'semantic-ui-react';

import styled from 'styled-components';

const SegmentCard = styled(Segment)`
  width: 100%;
  padding: 1.5em;
  margin: 0.5rem 0 !important;
`;

const Card = (props) => {
  return (
    <>
      <SegmentCard>
        {props.children}
      </SegmentCard>
    </>
  );
};

export default Card;
