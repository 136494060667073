import React from 'react';

import { Grid, Responsive } from 'semantic-ui-react';
import { Redirect } from "react-router-dom";

import styled from 'styled-components';
import Sidebar from '../../containers/Sidebar';

import DocumentAnalysis from './DocumentAnalysis';
import DocumentDeclined from './DocumentDeclined';

const ColumnContainer = styled(Grid.Column)`
  padding: 2.5em;
  margin-top: 3em;
`;

const DocumentManager = (props) => {
  if (! props.location || ! props.location.state || ! props.location.state.status) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <Responsive as={Sidebar} {...props} >
        <Grid.Row>
          <ColumnContainer>
            { props.location.state.status === 'pending' ? (
              <DocumentAnalysis name={props.location.state.name} />
            ) : (
              <DocumentDeclined name={props.location.state.name} />
            )}
          </ColumnContainer>
        </Grid.Row>
      </Responsive>
    </>
  )
};


export default DocumentManager;
