import React, { Component, useRef } from "react";
import styled from "styled-components";

import { Grid, Header, Button, Table } from "semantic-ui-react";
import {
  maskMoney,
  maskBankBilletNumber,
  maskTributeBilletNumber,
  maskCpfOrCnpj,
  maskPhoneNumber,
} from "../../services/masks";
import { connect } from "react-redux";
import { getPersistor } from "@rematch/persist";

import moment from "moment-timezone";
import "../../../node_modules/moment/min/locales";

import * as Storage from "../../services/storage";
import Card from "../../containers/Card";

import ReactToPrint from "react-to-print";
import CouponReceipt from "./CouponReceipt";

moment.locale("pt-br");

const Block = styled.div`
  padding: 0.8em 4em;
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  margin: 0 0 1em 0;
`;

const ScrollableContainer = styled.div`
  max-height: 33em;
  overflow-y: scroll;
`;

const PaymentBlockGrid = styled(Grid)`
  margin: 0 0 1em 0;
`;

const ColumnWithoutPadding = styled(Grid.Column)`
  padding: 0;
`;

const Signature = styled.div`
  padding-top: 30px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const ReceiptHeader = () => {
  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header>Recibo de Transação da Operação</Header>
        </TitleContainer>
      </Grid.Column>
    </Block>
  );
};

const OwnerInfo = () => {
  const owner_name = Storage.get("owner_name");
  const owner_document = Storage.get("owner_document");

  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header as="h4" style={{ paddingTop: "20px" }}>
            Pagamento processado pela empresa
          </Header>
        </TitleContainer>
      </Grid.Column>
      <Grid.Column>
        <strong>{owner_name}</strong>
      </Grid.Column>
      <Grid.Column>{maskCpfOrCnpj(owner_document)}</Grid.Column>
    </Block>
  );
};

const PayeeInfo = ({ sale }) => {
  const { payee } = sale;
  const { address } = payee;

  return (
    <Block>
      <Grid.Column>
        <TitleContainer>
          <Header as="h4" style={{ paddingTop: "20px" }}>
            Origem da transação
          </Header>
        </TitleContainer>
      </Grid.Column>
      <Grid.Column>
        <strong>{payee.name}</strong>
      </Grid.Column>
      <Grid.Column>
        <strong>CPF / CNPJ:</strong> {maskCpfOrCnpj(payee.document)}
      </Grid.Column>
      <Grid.Column>
        <strong>Telefone:</strong> {maskPhoneNumber(payee.phone)}
      </Grid.Column>
      <Grid.Column>
        <strong>Endereço:</strong>
        {` ${address.street}, ${address.number} - ${address.city}, ${address.state}`}
      </Grid.Column>
    </Block>
  );
};

const PayerInfo = ({ sale }) => {
  const { payer } = sale;

  const { address, document, name, phone } = payer;

  return (
    <Block>
      <div style={{ textAlign: "right" }}>
        <Grid.Column>
          <TitleContainer>
            <Header as="h4" style={{ paddingTop: "20px" }}>
              Cliente
            </Header>
          </TitleContainer>
        </Grid.Column>
        <Grid.Column>
          <strong>{name.toUpperCase()}</strong>
        </Grid.Column>
        <Grid.Column>
          <strong>CPF / CNPJ</strong> {maskCpfOrCnpj(document)}
        </Grid.Column>
        <Grid.Column>
          <strong>Telefone:</strong> {maskPhoneNumber(phone)}
        </Grid.Column>
        <Grid.Column>
          <strong>Endereço:</strong>
          {` ${address.street}, ${address.number} - ${address.city}, ${address.state}`}
        </Grid.Column>
      </div>
    </Block>
  );
};

const PaymentInfo = ({ sale }) => {
  const { payment_type } = sale;

  const parsed_date = moment(sale.created_at).tz("America/Sao_Paulo");

  return (
    <>
      <Block>
        <Grid.Column>
          <TitleContainer>
            <Header as="h4" style={{ paddingTop: "20px" }}>
              Dados de Pagamento
            </Header>
          </TitleContainer>
        </Grid.Column>
        <PaymentBlockGrid>
          <ColumnWithoutPadding width={10}>
            <Grid.Row>
              <strong>{payment_type.description}</strong>
            </Grid.Row>
            <Grid.Row>{`${payment_type.installments}x de R$ ${maskMoney(
              sale.installment_amount,
              true
            )}`}</Grid.Row>
          </ColumnWithoutPadding>
          <ColumnWithoutPadding width={3}>
            <strong>Data de Realização</strong>
          </ColumnWithoutPadding>
          <ColumnWithoutPadding width={3} textAlign="right">
            {parsed_date &&
              `${parsed_date.format("DD/MM/YYYY")} às ${parsed_date.format(
                "HH:MM"
              )}`}
          </ColumnWithoutPadding>
        </PaymentBlockGrid>
      </Block>
    </>
  );
};

const PaymentItems = ({ sale }) => {
  const { billets } = sale;

  return (
    <>
      <Block>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Linha Digitável</Table.HeaderCell>
                <Table.HeaderCell>Preço</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(billets).map((billet, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{billet.description}</Table.Cell>
                  <Table.Cell>
                    {billet.line.length === 48
                      ? maskTributeBilletNumber(billet.line)
                      : maskBankBilletNumber(billet.line)}
                  </Table.Cell>
                  <Table.Cell>{`R$ ${maskMoney(
                    billet.amount,
                    true
                  )}`}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={8}>
              <Table>
                <Table.Body>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Total destinado ao pagamento de boletos</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{`R$ ${maskMoney(
                        sale.original_amount,
                        true
                      )}`}</strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Total a pagar parcelado</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{`R$ ${maskMoney(
                        sale.total_with_interest,
                        true
                      )}`}</strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Total de acréscimo da operação</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{`R$ ${maskMoney(
                        sale.total_with_interest - sale.original_amount,
                        true
                      )}`}</strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row active>
                    <Table.Cell>
                      <strong>Taxa total de acréscimo da transação</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{`${
                        Math.floor(
                          (10000 *
                            (sale.total_with_interest - sale.original_amount)) /
                            sale.original_amount
                        ) / 100
                      }%`}</strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Block>
    </>
  );
};

const Footer = () => {
  return (
    <>
      <Block>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <div>
                <p>
                  <small>
                    Pagamento processado pelo Parcela Express. Em caso de dúvida
                    entre em contato pelo telefone (31) 3264-0337 ou consulte
                    nossos termos de uso e política de privacidade em
                    www.parcelaexpress.com.br
                  </small>
                </p>
                <small>
                  ATRAVÉS DO PRESENTE INSTRUMENTO, FICA CONFIRMADA A OPERAÇÃO
                  FINANCEIRA DESTINADA AO PAGAMENTO DE BOLETO.
                </small>
              </div>
              <div>
                <small>
                  O titular do cartão confirma a transação realizada e renuncia,
                  de forma irretratável, ao direito de cancelar ou contestar a
                  operação junto à operadora de cartão de crédito.
                </small>
              </div>
              <Signature>
                Assinatura do Cliente: ________________________________________
              </Signature>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Block>
    </>
  );
};

export const ReceiptData = ({ sale }) => {
  return (
    <>
      {sale && (
        <>
          <div style={{ textAlign: "center" }}>
            <ReceiptHeader />
            <OwnerInfo />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <PayeeInfo sale={sale} />
            <PayerInfo sale={sale} />
          </div>
          <PaymentInfo sale={sale} />
          <PaymentItems sale={sale} />
          <Footer />
        </>
      )}
    </>
  );
};

const ReceiptForm = ({ dispatch, sale, history }) => {
  const handleFinish = () => {
    const persistor = getPersistor();

    dispatch({ type: "RESET" });

    persistor.purge();
    persistor.flush();

    history.push("/admin/billets");
  };

  const componentRef = useRef();
  let couponReceiptRef = useRef();

  return (
    <>
      {sale && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <ButtonWrapper>
                  <Button
                    primary
                    type="button"
                    floated="right"
                    onClick={handleFinish}
                  >
                    Concluir
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Button secondary type="button" floated="right">
                        Imprimir
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                  <ReactToPrint
                    trigger={() => <Button secondary>Imprimir Cupom</Button>}
                    content={() => couponReceiptRef.current}
                  />
                  <div style={{ display: "none" }}>
                    <CouponReceipt data={sale} ref={couponReceiptRef} />
                  </div>
                </ButtonWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Card>
                  <ScrollableContainer>
                    <>
                      <PrintedReceipt sale={sale} ref={componentRef} />
                    </>
                  </ScrollableContainer>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

const PrintedReceiptContainer = styled.div`
  @media print {
    @page {
      margin-top: 1cm;
      margin-bottom: 0.5cm;
      margin-left: 0.5cm;
      margin-right: 0.5cm;
    }

    .payment-container {
      page-break-after: always;
    }
  }
`;

export class PrintedReceipt extends Component {
  render() {
    return (
      <PrintedReceiptContainer>
        <ReceiptData sale={this.props.sale} />
      </PrintedReceiptContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  sale: state.sale,
});

export const Receipt = connect(mapStateToProps)(ReceiptForm);
