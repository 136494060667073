import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component'

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/es/integration/react'

// import * as Sentry from '@sentry/browser';

import '../node_modules/semantic-ui-css/semantic.min.css';
import '../node_modules/react-notifications-component/dist/theme.css';
import './index.css';

import App from './App';
import store, { history } from "./store";

// Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate persistor={getPersistor()}>
        <ReactNotification />
        <App />
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
