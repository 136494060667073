import React, { useState, useEffect } from "react";

import { CSVLink } from "react-csv";
import { connect } from "react-redux";

import moment from "moment-timezone";

import { getPersistor } from "@rematch/persist";
import * as Storage from "../../services/storage";

import { maskMoney, maskDate, formatAmount } from "../../services/masks";
import { list as listReceivables } from "../../services/api/receivables";
import { getNextBusinessDay, isToday } from "../../services/date";

import { Table, Grid, Button, Dropdown, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import Header from "../../containers/Header";
import Card from "../../containers/Card";

import "./receivables.css";

const paymentTypeOptions = [
  {
    key: 1,
    value: "boleto",
    text: "Boleto",
  },
  {
    key: 2,
    value: "credit",
    text: "Crédito",
  },
];
const dateTypeOptions = [
  {
    key: 0,
    value: "created_at_range",
    text: "Data de Criação",
  },
  {
    key: 1,
    value: "paid_at_range",
    text: "Data da Transferência",
  },
];

const statusType = {
  pending: "Pendente",
  refunded: "Pago",
  paid: "Pago",
  canceled: "Cancelado",
};

const Receivables = ({ dispatch, history }) => {
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");

  const [dateTypeOption, setDateTypeOption] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isCreditDateFrom, setIsCreditDateFrom] = useState("");
  const [isCreditDateTo, setIsCreditDateTo] = useState("");

  const [receivables, setReceivables] = useState([]);
  const [allReceivablesByPeriod, setAllReceivablesByPeriod] = useState([]);

  const [filteredReceivables, setFilteredReceivables] = useState([]);

  const [filterMode, setFilterMode] = useState(false);
  const [load, setLoad] = useState(false);

  const [csvReceivablesData, setCsvReceivablesData] = useState([]);
  const [csvReceivablesHeaders, setCsvReceivablesHeaders] = useState([]);

  const formatedTotal = () => {
    const arrValues = !filterMode
      ? allReceivablesByPeriod.map(
          ({ status, created_at, paid_at, gross_amount, amount }) => {
            const checkValue = gross_amount - amount;
            const isBillet = checkValue.toFixed(2) === "2.40";

            const wasPaidToday = isToday(created_at) ? status : null;

            const isCredit_parsed_paid =
              !paid_at &&
              !isBillet &&
              !wasPaidToday &&
              moment(getNextBusinessDay(created_at)).tz("America/Sao_Paulo");

            if (isBillet || isCredit_parsed_paid) return Number(amount);
            return null;
          }
        )
      : filteredReceivables.map(
          ({ status, created_at, paid_at, gross_amount, amount }) => {
            const checkValue = gross_amount - amount;
            const isBillet = checkValue.toFixed(2) === "2.40";

            const wasPaidToday = isToday(created_at) ? status : null;

            const isCredit_parsed_paid =
              !paid_at &&
              !isBillet &&
              !wasPaidToday &&
              moment(getNextBusinessDay(created_at)).tz("America/Sao_Paulo");

            if (isBillet || isCredit_parsed_paid) return Number(amount);
            return null;
          }
        );

    const formatValues =
      arrValues.length > 0 &&
      arrValues.filter(Boolean).reduce((prev, next) => prev + next);

    return handleFormatAmout(formatValues);
  };

  const handleFormatAmout = (valueToFormat) => {
    const values = valueToFormat.toString().split(".");

    const real = values[0];
    const cents = values[1] && values[1].slice(0, 2);

    return formatAmount(real + "." + cents);
  };

  const handlePeriodRequest = async () => {
    try {
      const firstRequest = await handleReceivablesAllRequest();
      const pages = new Array(firstRequest[0].total_pages);

      let allData = firstRequest;
      for (const page of pages) {
        const updatePage = await handleReceivablesAllRequest(
          allData.length - 1,
          page
        );
        allData = [...allData, ...updatePage];
      }

      return allData;
    } catch (e) {
      console.log(e);
    } finally {
      setLoad(false);
    }
  };

  const handleReceivablesRequest = async (offset, isCredit) => {
    try {
      dispatch.loading.isLoading(true);
      const { client_id } = JSON.parse(Storage.get("tenant_credentials"));

      const list = await listReceivables(
        client_id,
        !isCredit ? dateFrom : isCreditDateFrom,
        !isCredit ? dateTo : isCreditDateTo,
        !offset ? 0 : offset,
        isCredit
      );

      return list;
    } catch (e) {
      console.log(e);

      const persistor = getPersistor();

      dispatch({ type: "RESET" });

      persistor.purge();
      persistor.flush();

      Storage.clear();

      history.push("/");
    } finally {
      dispatch.loading.isLoading(false);
    }
  };

  const handleReceivablesAllRequest = async (offset) => {
    try {
      const { client_id } = JSON.parse(Storage.get("tenant_credentials"));

      const list = await listReceivables(
        client_id,
        dateFrom,
        dateTo,
        !offset ? 0 : offset
      );
      return list;
    } catch (e) {
      console.log(e);
    }
  };

  const getCsvReceivablesData = () =>
    allReceivablesByPeriod.map(
      ({ amount, gross_amount, created_at, status, paid_at, id }) => {
        const parsed_date = moment(created_at).tz("America/Sao_Paulo");
        const parsed_paid = paid_at && moment(paid_at).tz("America/Sao_Paulo");

        const checkValue = gross_amount - amount;
        const isBillet = checkValue.toFixed(2) === "2.40";

        const wasPaidToday = isToday(created_at) ? status : null;

        const isCredit_parsed_paid =
          !paid_at &&
          !isBillet &&
          !wasPaidToday &&
          moment(getNextBusinessDay(created_at)).tz("America/Sao_Paulo");

        return {
          id,
          payment_type: isBillet ? "Boleto" : "Crédito",
          paid_at: isCredit_parsed_paid
            ? `${isCredit_parsed_paid.format(
                "DD/MM/YYYY"
              )} às ${isCredit_parsed_paid.format("HH:mm:ss")}`
            : `${parsed_paid.format("DD/MM/YYYY")} às ${parsed_paid.format(
                "HH:mm:ss"
              )}`,
          created_at: parsed_date
            ? `${parsed_date.format("DD/MM/YYYY HH:mm")}`
            : "",
          net_profit: `R$ ${maskMoney(amount, true)}`,
          gross_profit: `R$ ${maskMoney(gross_amount, true)}`,
          status: statusType[status],
        };
      }
    );

  useEffect(() => {
    (async () => {
      setCsvReceivablesHeaders([
        { label: "ID", key: "id" },
        { label: "Forma de pagamento", key: "payment_type" },
        { label: "Data de Pagamento", key: "paid_at" },
        { label: "Data de Criação", key: "created_at" },
        { label: "Valor Líquido", key: "net_profit" },
        { label: "Valor Bruto", key: "gross_profit" },
        { label: "Status", key: "status" },
      ]);

      setReceivables(await handleReceivablesRequest());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateFrom === "" || dateTo === "") return;
    (async () => {
      setLoad(true);
      setAllReceivablesByPeriod(await handlePeriodRequest());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (dateFrom === "" || dateTo === "") return;
    (async () => {
      const billet = await handleReceivablesRequest();
      const credit =
        isCreditDateFrom !== "" &&
        isCreditDateTo !== "" &&
        (await handleReceivablesRequest(0, true));

      const sortedArr =
        credit &&
        [...credit, ...billet].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

      setReceivables(sortedArr ? sortedArr : billet);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  return (
    <>
      <Header title="Recebíveis">
        {dateFrom && dateTo && (
          <CSVLink
            data={csvReceivablesData}
            target="_blank"
            filename={`lista-de-recibos-de-vendas.csv`}
            separator={";"}
            enclosingCharacter={`'`}
            headers={csvReceivablesHeaders}
            onClick={(event) => {
              setCsvReceivablesData(getCsvReceivablesData());
              return true;
            }}
          >
            <Button
              icon="file excel"
              {...(receivables.length === 0 && { disabled: true })}
              secondary
              loading={load}
              floated="right"
              title="Exportar para CSV"
            />
          </CSVLink>
        )}
      </Header>
      <Card>
        <Grid stackable>
          <Grid.Row>
            <>
              <Grid.Column mobile={8} tablet={4} computer={4}>
                <>
                  <label>Tipo de Data</label>
                  <div>
                    <Dropdown
                      selection
                      search
                      fluid
                      clearable
                      onChange={(e, { value }) => {
                        setDateTypeOption(value);
                        setFromDateFilter("");
                        setToDateFilter("");
                        if (dateFrom !== "") {
                          setDateFrom("");
                          setIsCreditDateFrom("");
                        }
                        if (dateTo !== "") {
                          setDateTo("");
                          setIsCreditDateTo("");
                        }
                      }}
                      options={dateTypeOptions}
                    />
                  </div>
                </>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={4}>
                <label>De</label>
                <div>
                  <DateInput
                    name="from_date_filter"
                    iconPosition="left"
                    value={fromDateFilter}
                    readOnly={!dateTypeOption}
                    clearable
                    fluid
                    onChange={(e, { value }) => {
                      const date = maskDate(value);

                      const yesterdayFromDate =
                        date !== ""
                          ? moment(date.split("/").reverse().join("-"))
                              .subtract(1, "days")
                              .startOf("day")
                              .toDate()
                          : "";

                      const fromDate =
                        date !== ""
                          ? moment(date.split("/").reverse().join("-"))
                              .startOf("day")
                              .toDate()
                          : "";

                      setFromDateFilter(date);

                      const yesterdayFromDateConverted = yesterdayFromDate.toLocaleString(
                        "en-US",
                        {
                          timeZone: "America/New_York",
                          hour12: false,
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }
                      );

                      const fromDateConverted = fromDate.toLocaleString(
                        "en-US",
                        {
                          timeZone: "America/New_York",
                          hour12: false,
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }
                      );

                      const yesterday_created_at =
                        dateTypeOption === "paid_at_range"
                          ? "created_at_range%5Bgte%5D=" +
                            yesterdayFromDateConverted
                          : "";
                      const key = dateTypeOption + "%5Bgte%5D=";

                      if (fromDateConverted) {
                        setDateFrom(`${key}${fromDateConverted}`);
                        setIsCreditDateFrom(yesterday_created_at);
                        return;
                      }
                      setDateFrom("");
                      setIsCreditDateFrom("");
                    }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={4}>
                <label>Até</label>
                <div>
                  <DateInput
                    name="to_date_filter"
                    iconPosition="left"
                    value={toDateFilter}
                    readOnly={!fromDateFilter}
                    minDate={fromDateFilter}
                    clearable
                    fluid
                    onChange={(e, { value }) => {
                      const date = maskDate(value);

                      const yesterdayToDate =
                        date !== ""
                          ? moment(date.split("/").reverse().join("-"))
                              .subtract(1, "days")
                              .endOf("day")
                              .toDate()
                          : "";

                      const toDate =
                        date !== ""
                          ? moment(date.split("/").reverse().join("-"))
                              .endOf("day")
                              .toDate()
                          : "";

                      setToDateFilter(date);

                      const yesterdayToDateConverted = yesterdayToDate.toLocaleString(
                        "en-US",
                        {
                          timeZone: "America/New_York",
                          hour12: false,
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }
                      );

                      const toDateConverted = toDate.toLocaleString("en-US", {
                        timeZone: "America/New_York",
                        hour12: false,
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      });

                      const yesterday_created_to =
                        dateTypeOption === "paid_at_range"
                          ? "created_at_range%5Blte%5D=" +
                            yesterdayToDateConverted
                          : "";
                      const key = dateTypeOption + "%5Blte%5D=";

                      if (toDateConverted) {
                        setDateTo(`${key}${toDateConverted}`);
                        setIsCreditDateTo(yesterday_created_to);
                        return;
                      }
                      setDateTo("");
                      setIsCreditDateTo("");
                    }}
                  />
                </div>
              </Grid.Column>
            </>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <>
                <label>Formas de Pagamento</label>
                <div>
                  <Dropdown
                    selection
                    search
                    fluid
                    clearable
                    onChange={(e, { value }) => {
                      if (value !== "") {
                        setFilterMode(true);
                        setFilteredReceivables(
                          receivables.filter(({ gross_amount, amount }) => {
                            const checkValue = gross_amount - amount;
                            const isBillet = checkValue.toFixed(2) === "2.40";

                            return value === "credit" ? !isBillet : isBillet;
                          })
                        );
                        return;
                      }
                      setFilterMode(false);
                    }}
                    options={paymentTypeOptions}
                  />
                </div>
              </>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Table celled striped role="grid" aria-labelledby="header">
        <Table.Header
          style={{
            display: "table",
            width: "100%",
            tableLayout: "fixed",
            paddingRight: "10px",
          }}
        >
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              Data da criação
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              ID da Transação
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Data da Transferência
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Forma de Pagamento
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Status da Transferência
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Valor Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          className="tableList"
          onScroll={(event) => {
            if (filterMode) return;
            const bottom =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottom) {
              (async () => {
                try {
                  dispatch.loading.isLoading(true);
                  const { client_id } = JSON.parse(
                    Storage.get("tenant_credentials")
                  );
                  const updatedReceivables = await listReceivables(
                    client_id,
                    dateFrom,
                    dateTo,
                    receivables.length - 1
                  );

                  if (updatedReceivables.length > 0) {
                    setReceivables([...receivables, ...updatedReceivables]);
                    return;
                  }
                } catch (e) {
                  console.log(e);
                } finally {
                  dispatch.loading.isLoading(false);
                }
              })();
            }
          }}
        >
          {!filterMode
            ? receivables.map(
                (
                  { amount, created_at, gross_amount, paid_at, id, status },
                  index
                ) => {
                  const parsed_date = moment(created_at).tz(
                    "America/Sao_Paulo"
                  );
                  const parsed_paid =
                    paid_at && moment(paid_at).tz("America/Sao_Paulo");

                  const checkValue = gross_amount - amount;
                  const isBillet = checkValue.toFixed(2) === "2.40";

                  const wasPaidToday = isToday(created_at) ? status : null;

                  const isCredit_parsed_paid =
                    !paid_at &&
                    !isBillet &&
                    !wasPaidToday &&
                    moment(getNextBusinessDay(created_at)).tz(
                      "America/Sao_Paulo"
                    );

                  const idArr = id.split("");
                  const rowLength = idArr.length / 2;

                  const firstRow = idArr.slice(0, rowLength).join("");
                  const secondRow = idArr.slice(rowLength).join("");

                  return (
                    <Table.Row
                      key={index}
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <Table.Cell textAlign="center">
                        {parsed_date &&
                          `${parsed_date.format(
                            "DD/MM/YYYY"
                          )} às ${parsed_date.format("HH:mm:ss")}`}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {firstRow} {secondRow}
                      </Table.Cell>
                      {wasPaidToday === "pending" ? (
                        <Table.Cell textAlign="center">
                          {statusType[status]}
                        </Table.Cell>
                      ) : (
                        <Table.Cell textAlign="center">
                          {isCredit_parsed_paid
                            ? `${isCredit_parsed_paid.format(
                                "DD/MM/YYYY"
                              )} às ${isCredit_parsed_paid.format("HH:mm:ss")}`
                            : `${parsed_paid.format(
                                "DD/MM/YYYY"
                              )} às ${parsed_paid.format("HH:mm:ss")}`}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {isBillet ? "Boleto" : "Crédito"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {isCredit_parsed_paid ? "Pago" : statusType[status]}
                      </Table.Cell>
                      <Table.Cell textAlign="center">R$ {amount}</Table.Cell>
                    </Table.Row>
                  );
                }
              )
            : filteredReceivables.map(
                (
                  { amount, gross_amount, created_at, paid_at, id, status },
                  index
                ) => {
                  const parsed_date = moment(created_at).tz(
                    "America/Sao_Paulo"
                  );
                  const parsed_paid =
                    paid_at && moment(paid_at).tz("America/Sao_Paulo");

                  const checkValue = gross_amount - amount;
                  const isBillet = checkValue.toFixed(2) === "2.40";

                  const wasPaidToday = isToday(created_at) ? status : null;

                  const isCredit_parsed_paid =
                    !paid_at &&
                    !isBillet &&
                    !wasPaidToday &&
                    moment(getNextBusinessDay(created_at)).tz(
                      "America/Sao_Paulo"
                    );

                  const idArr = id.split("");
                  const rowLength = idArr.length / 2;

                  const firstRow = idArr.slice(0, rowLength).join("");
                  const secondRow = idArr.slice(rowLength).join("");

                  return (
                    <Table.Row
                      key={index}
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <Table.Cell textAlign="center">
                        {parsed_date &&
                          `${parsed_date.format(
                            "DD/MM/YYYY"
                          )} às ${parsed_date.format("HH:mm:ss")}`}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {firstRow} {secondRow}
                      </Table.Cell>
                      {wasPaidToday === "pending" ? (
                        <Table.Cell textAlign="center">
                          {statusType[status]}
                        </Table.Cell>
                      ) : (
                        <Table.Cell textAlign="center">
                          {isCredit_parsed_paid
                            ? `${isCredit_parsed_paid.format(
                                "DD/MM/YYYY"
                              )} às ${isCredit_parsed_paid.format("HH:mm:ss")}`
                            : `${parsed_paid.format(
                                "DD/MM/YYYY"
                              )} às ${parsed_paid.format("HH:mm:ss")}`}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {isBillet ? "Boleto" : "Crédito"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {isCredit_parsed_paid ? "Pago" : statusType[status]}
                      </Table.Cell>
                      <Table.Cell textAlign="center">R$ {amount}</Table.Cell>
                    </Table.Row>
                  );
                }
              )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              {dateFrom &&
                dateTo &&
                (!filterMode ? (
                  <Button
                    floated="right"
                    icon
                    loading={load}
                    labelPosition="left"
                    color="blue"
                    size="small"
                  >
                    <Icon name="balance scale" />
                    <span style={{ fontWeight: "bold" }}>Total:</span> R$
                    {allReceivablesByPeriod.length > 0 && formatedTotal()}
                  </Button>
                ) : (
                  <Button
                    floated="right"
                    icon
                    loading={load}
                    labelPosition="left"
                    color="blue"
                    size="small"
                  >
                    <Icon name="balance scale" />
                    <span style={{ fontWeight: "bold" }}>Total:</span> R$
                    {filteredReceivables.length > 0 && formatedTotal()}
                  </Button>
                ))}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};
export default connect()(Receivables);
