import * as Api from './api';

export const getPaymentTypes = async (tenantId, ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const res = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/payment-types`, 'GET', headers);

  return res.data.data;
};

export const selectList = (payment_types) => payment_types.map(listData => {
  return {
    key: listData.id,
    value: listData.id,
    text: listData.description
  }
});
