import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Grid, Responsive } from 'semantic-ui-react';

import styled from 'styled-components';

import Card from '../../containers/Card';
import Sidebar from '../../containers/Sidebar';

import BusinessForm from './BusinessForm';
import IndividualForm from './IndividualForm';
import AccountTypeFields from './AccountTypeFields';

import { listOwners } from '../../services/api/owners';

const ColumnContainer = styled(Grid.Column)`
  padding: 2.5em;
  margin-top: 3em;
`;

function redirect(props) {
  props.history.push({
    pathname: '/adherence-monitoring',
    state: {
      status: 'pending',
      name: props.name
    }
  });
}

const DataForm = (props) => {
  if (props.account === 'business') {
    return <BusinessForm {...props} onFinish={redirect} />
  }
  if (props.account === 'individual') {
    return <IndividualForm {...props} onFinish={redirect} />
  }
  return <></>
}

const Credentiation = (props) => {

  const [account, setAccount] = useState('business');

  const { sidebarIsVisible, updateOwner } = props;

  const onChange = (e, state) => {
    setAccount(state.value);
  };

  const onUpdate = (e, state) => {
    sidebarIsVisible.closeSidebar();
  };

  useEffect(() => {
    (async () => {
      const appOwners = await listOwners();
      const defaultOwner = appOwners.find(owner => owner.value === process.env.REACT_APP_DEFAULT_OWNER);
      updateOwner(defaultOwner);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Responsive onUpdate={onUpdate} as={Sidebar} {...props}>
      <Grid.Row>
        <ColumnContainer>
          <Responsive fireOnMount as={Card}>
            <AccountTypeFields onChange={onChange} />
            <DataForm account={account} {...props} />
          </Responsive>
        </ColumnContainer>
      </Grid.Row>
    </Responsive>
  );
};

const mapStateToProps = (state) => ({
    sidebarIsVisible: state.sidebarIsVisible
  });

const mapDispatchToProps = dispatch => ({
    updateOwner: dispatch.owner.updateOwner,
    sidebarIsVisible: dispatch.sidebarIsVisible
  });

export default connect(mapStateToProps, mapDispatchToProps)(Credentiation);
