import React from 'react';

import { Card as CardView, Grid } from 'semantic-ui-react';

import { round } from '../services/math';
import { maskMoney } from '../services/masks';

import styled from 'styled-components';

const CustomCardGroup = styled(Grid.Row)`
  margin-right: 1em !important;
`

const CustomCardColumn = styled(Grid.Column)`
  margin-top: 0.75em !important;
`

const CustomCard = styled(CardView)`
  margin-left: 0.75em !important;
  margin-right: 0.75em !important;
`

const SimulatorStatistics = (props) => {
  return (
    <>
      <CustomCardGroup centered stretched>
        <CustomCardColumn width={5}>
          <CustomCard fluid header="Valor Original"
            description={(props.original_amount !== undefined && props.original_amount !== '' && props.original_amount !== null)? `R$ ${maskMoney(round(props.original_amount, 2), true)}` : 'R$ 0,00' } />
        </CustomCardColumn>
        <CustomCardColumn width={5}>
          <CustomCard fluid header="Parcelas"
            description={ (props.installments !== undefined)? ` ${props.installments}x R$ ${maskMoney(props.installment_amount, true, 2)}` : 'R$ 0,00' }></CustomCard>
        </CustomCardColumn>
        <CustomCardColumn width={5}>
          <CustomCard fluid header="Valor a Cobrar"
            description={ `R$ ${maskMoney(props.total_with_interest, true)}` }></CustomCard>
        </CustomCardColumn>
      </CustomCardGroup>
    </>
  );
}

export default SimulatorStatistics;
