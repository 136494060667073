import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, Grid, Message, Responsive, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import BilletsModal from './BilletModal';
import Card from '../../containers/Card';
import { round } from '../../services/math';

import { maskMoney, maskBankBilletNumber, maskTributeBilletNumber } from '../../services/masks';

// Padding vertical | horizontal
const GridContainer = styled(Grid)`
  padding: 0 1em !important;
`;

const ButtonWrapper = styled(Grid.Column)`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const BilletsForm = (props) => {
  const { dispatch, billets, loadingModal } = props;

  const [modalIsOpen, toggleModal] = useState(false);

  // On mount be sure that the states are mounted
  useEffect(() => {
    // When this view is mounted, force the modal to be closed
    // When the modal closes by save, this action is triggered
    toggleModal(false);
  }, []);

  const handleAdd = async (billet) => {
    dispatch.billets.addBilletAndUpdateSimulator(billet);
  };

  const addNewDebitModal = () => {
    toggleModal(!modalIsOpen);
  };

  return (
    <>
      <Form
        // enableReinitialize
        onSubmit={async (event, data) => {
          return props.history.push('/admin/billets/payer');
        }}
      >
        <GridContainer stackable>
          <Grid.Row>
            {billets.length === 0 && (
              <ButtonWrapper>
                <Button
                  name='add'
                  icon='plus'
                  type='button'
                  title='Adicionar débito'
                  onClick={addNewDebitModal}
                ></Button>
              </ButtonWrapper>
            )}
            <ButtonWrapper width={2} floated='right'>
              <Button fluid primary type='submit'>
                Salvar
              </Button>
            </ButtonWrapper>
          </Grid.Row>
        </GridContainer>
        {billets.length !== 0 ? (
          <>
            <Responsive fireOnMount as={Card}>
              <GridContainer stackable>
                {billets.map((billet, index) => (
                  <Grid.Row key={index}>
                    <Grid.Column width={3}>
                      <label>
                        <b>Descrição</b>
                      </label>{' '}
                      <br></br>
                      <div>{billet.description}</div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <label>
                        <b>Linha Digitavel</b>
                      </label>{' '}
                      <br></br>
                      <div>
                        {billet.line.length === 48
                          ? maskTributeBilletNumber(billet.line)
                          : maskBankBilletNumber(billet.line)}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <label>
                        <b>Valor</b>
                      </label>
                      <div>{`R$ ${maskMoney(round(billet.amount, 2), true)}`}</div>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Button
                        fluid
                        name='remove'
                        icon='remove'
                        type='button'
                        title='Remover débito'
                        onClick={() => {
                          dispatch.billets.removeBilletAndUpdateSimulator(billet);
                        }}
                      ></Button>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </GridContainer>
            </Responsive>
          </>
        ) : (
            <>
              <Message negative>
                <p>{'Ainda não existem débitos cadastrados'}</p>
              </Message>
            </>
          )}
      </Form>
      <BilletsModal
        isOpen={modalIsOpen}
        loadingModal={loadingModal}
        addAction={handleAdd}
        billets={billets}
        toggle={() => toggleModal(!modalIsOpen)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  billets: state.billets,
  loadingModal: state.loadingModal,
});

export default connect(mapStateToProps)(BilletsForm);
