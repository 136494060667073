import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { getPersistor } from "@rematch/persist";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import moment from "moment-timezone";

import { maskMoney, maskDate } from "../../services/masks";
import {
  getPaymentTypes,
  selectList as getPaymentTypeOptions,
} from "../../services/api/paymentTypes";
import * as Storage from "../../services/storage";

import {
  list as listSales,
  updateList as updateListSales,
  deleteSale,
} from "../../services/api/sales";

import {
  Table,
  Grid,
  Button,
  Modal,
  Message,
  Dropdown,
  Form,
  Responsive,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import Header from "../../containers/Header";
import Card from "../../containers/Card";

import IconButton from "../../components/IconButton";
import CouponReceipt from "./CouponReceipt";
import { PrintedReceipt } from "./Receipt";

import "./salesList.css";

const filterOptions = [
  {
    key: 1,
    value: "payment_type_description",
    text: "Forma de Pagamento",
  },
  {
    key: 2,
    value: "from_to_date",
    text: "De / Até",
  },
  {
    key: 3,
    value: "description",
    text: "Descrição",
  },
];

const ReceiptModal = ({
  toggle,
  sale,
  isOpen,
  deleteMode,
  handleDeleteSale,
}) => {
  const componentRef = useRef();
  let couponReceiptRef = useRef();

  return (
    <>
      <Modal open={isOpen || false}>
        <Modal.Header>
          {!deleteMode
            ? "Segunda via de recibo "
            : "Tem certeza que deseja excluir o recibo?"}
        </Modal.Header>
        <Modal.Content scrolling>
          <PrintedReceipt sale={sale} ref={componentRef} />
        </Modal.Content>
        <Modal.Actions style={{ paddingBottom: "0.5rem" }}>
          <Button primary onClick={toggle}>
            Fechar
          </Button>
          <ReactToPrint
            trigger={() => <Button secondary>Imprimir Cupom</Button>}
            content={() => couponReceiptRef.current}
          />
          <ReactToPrint
            trigger={() => <Button secondary>Imprimir Cupom</Button>}
            content={() => couponReceiptRef.current}
          />
          {deleteMode && (
            <Button color="red" onClick={() => handleDeleteSale(sale.sale_id)}>
              Deletar
            </Button>
          )}
          <div style={{ display: "none" }}>
            <CouponReceipt data={sale} ref={couponReceiptRef} />
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SalesList = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [sales, setSales] = useState([]);
  const [currentSale, setCurrentSale] = useState({});
  const [editMode, setEditMode] = useState(false);

  const [limit] = useState(40);

  const [csvSalesData, setCsvSalesData] = useState([]);
  const [csvSalesHeaders, setCsvSalesHeaders] = useState([]);

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [query, setQuery] = useState(null);

  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions.find((filter) => filter.key === 1)
  );

  const { dispatch } = props;

  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);

  const openReceipt = (sale, deleteModal) => {
    setCurrentSale(sale);
    setOpenDeleteModal(deleteModal);
    setOpenModal(!openModal);
  };

  const getCsvSalesData = (salesData) =>
    salesData.map((saleData) => {
      let parsed_date;

      try {
        parsed_date = moment(saleData.created_at).tz("America/Sao_Paulo");
      } catch (e) {}

      const paymentTypeDescription =
        (saleData.payment_type_description &&
          (saleData.payment_type_description || "")) ||
        "";

      return {
        description: saleData.description,
        payer_name: saleData.payer.name,
        payment_type_description: paymentTypeDescription,
        created_at: parsed_date
          ? `${parsed_date.format("DD/MM/YYYY HH:mm")}`
          : "",
        original_amount: `R$ ${maskMoney(saleData.original_amount, true)}`,
      };
    });

  useEffect(() => {
    (async () => {
      try {
        dispatch.loading.isLoading(true);

        setCsvSalesHeaders([
          { label: "Descrição", key: "description" },
          { label: "Pagador", key: "payer_name" },
          { label: "Forma de Pagamento", key: "payment_type_description" },
          { label: "Data de Criação", key: "created_at" },
          { label: "Total", key: "original_amount" },
        ]);

        const tenantId = Storage.get("uid");
        const selectedOwner = Storage.get("selected_owner");

        const salesData = await listSales(
          tenantId,
          selectedOwner,
          limit,
          "?type=transaction"
        );
        const parsedSalesData = salesData.filter(
          (sale) => sale.created_at && null !== sale.created_at
        );

        setSales(parsedSalesData);

        const getPaymentTypesResponse = await getPaymentTypes(
          tenantId,
          selectedOwner
        );

        setPaymentTypeOptions(getPaymentTypeOptions(getPaymentTypesResponse));
      } catch (e) {
        console.log(e);

        const persistor = getPersistor();

        dispatch({ type: "RESET" });

        persistor.purge();
        persistor.flush();

        Storage.clear();

        props.history.push("/");
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        dispatch.loading.isLoading(true);

        const tenantId = Storage.get("uid");
        const selectedOwner = Storage.get("selected_owner");

        setSales(
          await listSales(
            tenantId,
            selectedOwner,
            limit,
            "?type=transaction" + query
          )
        );
      } catch (e) {
        console.log(e);
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleDeleteSale = async (sale) => {
    try {
      dispatch.loading.isLoading(true);

      const tenantId = Storage.get("uid");
      const selectedOwner = Storage.get("selected_owner");

      setOpenDeleteModal(false);
      setOpenModal(false);
      await deleteSale(tenantId, selectedOwner, sale);
      const salesData = await listSales(tenantId, selectedOwner, limit);
      const parsedSalesData = salesData.filter(
        (sale) => sale.created_at && null !== sale.created_at
      );

      setSales(parsedSalesData);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch.loading.isLoading(false);
    }
  };

  return (
    <>
      <Header
        title="Vendas"
        csvData={csvSalesData}
        csvHeader={csvSalesHeaders}
        setCsvData={setCsvSalesData}
        getCsvData={getCsvSalesData}
        data={sales}
      >
        <Button
          icon="plus"
          primary
          floated="right"
          title="Criar recibo"
          onClick={() => props.history.push("/admin/sales/simulator")}
        />
        <CSVLink
          data={csvSalesData}
          target="_blank"
          filename={`lista-de-recibos-de-vendas.csv`}
          separator={";"}
          enclosingCharacter={`'`}
          headers={csvSalesHeaders}
          onClick={(event) => {
            setCsvSalesData(getCsvSalesData(sales));
            return true;
          }}
        >
          <Button
            icon="file excel"
            {...(sales.length === 0 && { disabled: true })}
            secondary
            floated="right"
            title="Exportar para CSV"
          />
        </CSVLink>
        <Button
          icon="edit"
          color={!editMode ? "red" : "grey"}
          floated="right"
          title="Entrar em modo edição"
          onClick={() => setEditMode(!editMode)}
        />
      </Header>
      <Card>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <label>Filtros</label>
              <div>
                <Dropdown
                  selection
                  search
                  fluid
                  value={selectedFilter.value}
                  onChange={(e, data) => {
                    setSelectedFilter(
                      filterOptions.find(
                        (filter) => filter.value === data.value
                      )
                    );
                    setFromDateFilter("");
                    setToDateFilter("");
                  }}
                  options={filterOptions}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              {selectedFilter.value === "from_to_date" && (
                <>
                  <Grid stackable>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>De</label>
                      <div>
                        <DateInput
                          name="from_date_filter"
                          iconPosition="left"
                          value={fromDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            if (value === "") {
                              setFromDateFilter("");
                              setToDateFilter("");

                              setQuery("");
                              return;
                            }
                            const date = maskDate(value);
                            const fromDate =
                              date !== ""
                                ? moment(date.split("/").reverse().join("-"))
                                    .startOf("day")
                                    .toDate()
                                : "";
                            const toDate =
                              toDateFilter !== ""
                                ? moment(
                                    toDateFilter.split("/").reverse().join("-")
                                  )
                                    .endOf("day")
                                    .toDate()
                                : "";

                            setFromDateFilter(date);

                            const fromDateConverted = fromDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            const toDateConverted = toDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            if (toDate !== "") {
                              setQuery(
                                "&from=" +
                                  fromDateConverted +
                                  "&" +
                                  "to=" +
                                  toDateConverted
                              );

                              return;
                            }

                            setQuery("&from=" + fromDateConverted);
                            return;
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>Até</label>
                      <div>
                        <DateInput
                          name="to_date_filter"
                          iconPosition="left"
                          value={toDateFilter}
                          readOnly={!fromDateFilter}
                          minDate={fromDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            if (value === "") {
                              setToDateFilter("");
                              setQuery("");
                              return;
                            }

                            const date = maskDate(value);
                            const fromDate =
                              fromDateFilter !== ""
                                ? moment(
                                    fromDateFilter
                                      .split("/")
                                      .reverse()
                                      .join("-")
                                  )
                                    .startOf("day")
                                    .toDate()
                                : "";
                            const toDate =
                              date !== ""
                                ? moment(date.split("/").reverse().join("-"))
                                    .endOf("day")
                                    .toDate()
                                : "";
                            setToDateFilter(date);

                            const fromDateConverted = fromDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            const toDateConverted = toDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            if (fromDateFilter !== "") {
                              setQuery(
                                "&from=" +
                                  fromDateConverted +
                                  "&" +
                                  "to=" +
                                  toDateConverted
                              );

                              return;
                            }
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid>
                </>
              )}
              {selectedFilter.value === "payment_type_description" && (
                <>
                  <label>Formas de Pagamento</label>
                  <div>
                    <Dropdown
                      selection
                      fluid
                      clearable
                      onChange={(e, { value, options }) => {
                        if (value === "") return setQuery("");

                        const { text } = options.find(
                          (val) => val.value === value
                        );
                        setQuery("&" + selectedFilter.value + "=" + text);
                        return;
                      }}
                      options={paymentTypeOptions}
                    />
                  </div>
                </>
              )}
              {selectedFilter.value === "description" && (
                <Form>
                  <label>Descrição</label>
                  <Form.Field>
                    <div>
                      <input
                        type="text"
                        onChange={(e, { value, options }) => {
                          if (value === "") return setQuery("");

                          const option = options.find(
                            (val) => val.value === value
                          );

                          setQuery(
                            "&" + selectedFilter.value + "=" + option.value
                          );
                          return;
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      {sales.length > 0 ? (
        <>
          <Responsive fireOnMount as={Card}>
            <Table celled striped role="grid" aria-labelledby="header">
              <Table.Header
                style={{
                  display: "table",
                  width: "100%",
                  tableLayout: "fixed",
                  paddingRight: "10px",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell>Pagador</Table.HeaderCell>
                  <Table.HeaderCell>Forma de Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Data de Criação</Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{ fontSize: 12 }}>
                    {!editMode ? "2º Via" : "Deletar"}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body
                className="tableList"
                onScroll={(event) => {
                  if (
                    query &&
                    query.split("?")[1].split("=")[0] !==
                      "payment_type_description"
                  )
                    return;
                  const bottom =
                    event.target.scrollHeight - event.target.scrollTop ===
                    event.target.clientHeight;
                  if (bottom) {
                    (async () => {
                      try {
                        dispatch.loading.isLoading(true);

                        const offset = sales[sales.length - 1].sale_id;
                        const tenantId = Storage.get("uid");
                        const selectedOwner = Storage.get("selected_owner");
                        const updatedList = await updateListSales(
                          tenantId,
                          selectedOwner,
                          limit,
                          offset,
                          query
                        );

                        if (updatedList.length > 0) {
                          setSales([...sales, ...updatedList]);
                          return;
                        }
                      } catch (e) {
                        console.log(e);
                      } finally {
                        dispatch.loading.isLoading(false);
                      }
                    })();
                  }
                }}
              >
                {sales.map((sale, index) => {
                  const parsed_date = moment(sale.created_at).tz(
                    "America/Sao_Paulo"
                  );

                  return (
                    <Table.Row
                      key={index}
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <Table.Cell>{sale.description.toUpperCase()}</Table.Cell>
                      <Table.Cell>{sale.payer.name}</Table.Cell>
                      <Table.Cell>
                        {sale.payment_type && sale.payment_type.description}
                      </Table.Cell>
                      <Table.Cell>
                        {parsed_date &&
                          `${parsed_date.format(
                            "DD/MM/YYYY"
                          )} às ${parsed_date.format("HH:mm:ss")}`}
                      </Table.Cell>
                      <Table.Cell>
                        R$ {`${maskMoney(sale.original_amount, true)}`}
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        {!editMode ? (
                          <IconButton
                            title="Segunda via do Recibo"
                            name="file alternate"
                            onClick={() => openReceipt(sale, false)}
                          />
                        ) : (
                          <IconButton
                            color="red"
                            title="Delete sale"
                            name="trash"
                            onClick={() => openReceipt(sale, true)}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Responsive>
          <ReceiptModal
            isOpen={openModal}
            sale={currentSale}
            deleteMode={openDeleteModal}
            toggle={() => setOpenModal(!openModal)}
            handleDeleteSale={handleDeleteSale}
          />
        </>
      ) : (
        <Message negative>
          <p>Registros não encontrados</p>
        </Message>
      )}
    </>
  );
};

export default connect()(SalesList);
