import React, { useState } from 'react';

import styled from 'styled-components';

import { Grid } from 'semantic-ui-react';
import { ErrorMessage, Field } from 'formik';
import { states } from '../services/states';

import * as validators from '../services/validators';

import Input from './Input';
import Dropdown from './Dropdown';

import { get as requestGet } from '../services/request';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
  font-size: 10px;
`;


const AddressForm = (props) => {
  const { setFieldValue, touched, errors } = props;
  const [isSearching, setIsSearching] = useState(false);

  const handleZipcodeSearch = async (e) => {
    const zipcode = e.target.value.replace('-', '');

    if (zipcode.length === 8) {
      try {
        setIsSearching(true);

        const { data } = await requestGet(
          `https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json`
        );

        if (data.erro) {
          throw new Error('Zipcode not found');
        }
        const { logradouro: street, complemento: complement, bairro: district, localidade: city, uf: state } = data;

        setFieldValue('address[street]', street);
        setFieldValue('address[complement]', complement);
        setFieldValue('address[district]', district);
        setFieldValue('address[city]', city);
        setFieldValue('address[state]', state);
      } catch (e) {
        setFieldValue('address[street]', '');
        setFieldValue('address[complement]', '');
        setFieldValue('address[district]', '');
        setFieldValue('address[city]', '');
        setFieldValue('address[state]', '');
      } finally {
        setIsSearching(false);
      }
    }
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>CEP</label><label style={{ color: 'red' }}>*</label>
          <Field
            name='address[postal_code]'
            type='text'
            component={Input}
            fluid
            maxLength={9}
            onChange={(e) => {
              const masked = e.target.value.length > 5 && !e.target.value.includes('-') ? e.target.value.substr(0, 5) + '-' + e.target.value.substr(5, 3) : e.target.value;
              setFieldValue('address[postal_code]', masked.replace('-', ''));
              handleZipcodeSearch(e);
            }}
            validate={validators.notEmpty}
            error={touched?.address?.postal_code && errors?.address?.postal_code !== undefined}
          />
          <Error name='address[postal_code]' component='div' />
        </Grid.Column>
        <Grid.Column width={10}>
          <label>Logradouro</label><label style={{ color: 'red' }}>*</label>
          <Field
            name='address[street]'
            type='text'
            component={Input}
            fluid
            {...(isSearching && { loading: true })}
            validate={validators.notEmpty}
            error={touched?.address?.street && errors?.address?.street !== undefined}
          />
          <Error name='address[street]' component='div' />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>Número</label><label style={{ color: 'red' }}>*</label>
          <Field name='address[number]' type='text' component={Input} fluid {...(isSearching && { loading: true })}
            validate={validators.notEmpty}
            error={touched?.address?.number && errors?.address?.number !== undefined} />
          <Error name='address[number]' component='div' />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <label>Bairro</label><label style={{ color: 'red' }}>*</label>
          <Field name='address[district]' type='text' component={Input} fluid {...(isSearching && { loading: true })}
            validate={validators.notEmpty}
            error={touched?.address?.district && errors?.address?.district !== undefined} />
          <Error name='address[district]' component='div' />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Complemento</label>
          <Field
            name='address[complement]'
            type='text'
            component={Input}
            fluid
            {...(isSearching && { loading: true })}
          />
          <Error name='address[complement]' component='div' />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Cidade</label><label style={{ color: 'red' }}>*</label>
          <Field name='address[city]' type='text' component={Input} fluid {...(isSearching && { loading: true })}
            validate={validators.notEmpty}
            error={touched?.address?.city && errors?.address?.city !== undefined} />
          <Error name='address[city]' component='div' />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>UF</label><label style={{ color: 'red' }}>*</label>
          <Field
            name='address[state]'
            type='text'
            component={Dropdown}
            options={states}
            fluid
            {...(isSearching && { loading: true })}
            validate={validators.notEmpty}
            error={touched?.address?.state && errors?.address?.state !== undefined}
          />
          <Error name='address[state]' component='div' />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default AddressForm;
