import React, { Component } from 'react';
import styled from 'styled-components';

import TaxCoupon from '../sales/TaxCoupon';

const PrintedReceiptContainer = styled.div`
  @media print {
    @page {
      page-break-before: avoid;
      size: auto;
      margin: 0mm;
    }
  }
`;

class CouponReceipt extends Component {
  render() {
    return (
      <PrintedReceiptContainer>
        <TaxCoupon data={this.props.data} />
      </PrintedReceiptContainer>
    );
  }
}

export default CouponReceipt;
