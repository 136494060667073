import React, { useState } from 'react';
import { Modal, Button, Input, Form } from 'semantic-ui-react';
import { dangerNotification, successNotification } from '../../services/notification';
import { resetPassword } from '../../services/api/auth';
import styled from 'styled-components';

const ButtonStyled = styled(Button)`
  margin: 0 5px 10px 0 !important;
`;

const ModalForgotPassword = (props) => {
  const open = props.open || false;
  const { closeModal } = props;
  const [email, setEmail] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      dangerNotification('Atenção', 'Favor informar o email de recuperação!');
      return;
    }

    try {
      await resetPassword(email);

      closeModal();
      successNotification('Success', "Foi enviado para seu email um link para redefinir sua senha.");
    } catch (error) {
      const { errorCode } = error.response.data;
      let message = 'Ocorreu um erro inesperado! Tente novamente mais tarde.';

      if(errorCode === 'auth/user-not-found') {
        message = 'Não encontramos um usuário com o email informado!';
      } else if(errorCode === 'auth/invalid-email') {
        message = 'O email informado não é válido!';
      }

      dangerNotification('Atenção', message);
    }
  };

  const handleOnMount = (event) => {
    setEmail('');
  };

  return (
    <Modal
      open={open}
      onClose={(e) => { closeModal() }}
      as={Form} onSubmit={(e) => handleSubmit(e)}
      onMount={(e) => handleOnMount(e)}
    >
      <Modal.Header>Redefinição de Senha</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Email de recuperação</p>
          <Input
            fluid
            placeholder='Informe seu email para enviar-mos instruções de recuperação de senha'
            type="email"
            icon={{ name: 'mail', link: true, onClick: (e) => setEmail(e.target.value) }}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ButtonStyled
          floated="right"
          primary
        >
          Redefinir sua senha
        </ButtonStyled>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalForgotPassword;
