export const login = {
  state: {
    email: '',
    password: '',
    loading: false,
  },

  reducers: {},
};

export const user = {
  state: {
    owner: '',
    role: '',
  },
  reducers: {
    updateUser(state, payload) {
      return { ...state, ...payload };
    },
  },
};

export const simulator = {
  state: {},
  reducers: {
    updateSimulator(state, payload) {
      return { ...state, ...payload };
    },
  },
};

export const payer = {
  state: {},
  reducers: {
    updatePayer(state, payload) {
      return payload;
    },
  },
};

export const loading = {
  state: false,
  reducers: {
    isLoading(state, payload) {
      return payload;
    },
  },
};

export const loadingModal = {
  state: false,
  reducers: {
    isLoading(state, payload) {
      return payload;
    },
  },
};

export const sidebarIsVisible = {
  state: false,
  reducers: {
    openSidebar(state, payload) {
      return true;
    },
    closeSidebar(state, payload) {
      return false;
    },
    toggleSidebar(state) {
      return !state;
    },
  },
};

export const payment_types = {
  state: [],
  reducers: {
    updatePaymentTypes(state, payload) {
      return payload;
    },
  },
};

export const sale = {
  state: {},
  reducers: {
    createSale(state, payload) {
      return payload;
    },
    clearSale(state, payload) {
      return {};
    },
  },
};

export const owner = {
  state: {
    key: '',
    value: '',
    text: '',
    termsOfService: '',
  },
  reducers: {
    updateOwner(state, payload) {
      return payload;
    },
  },
};

export const billets = {
  state: [],
  reducers: {
    addBillet(state, payload) {
      return [...state, payload];
    },
    removeBillet(state, billet) {
      return state.filter((billetData) => billetData.line !== billet.line);
    },
    clearBillets(state) {
      return [];
    },
  },
  effects: (dispatch) => ({
    addBilletAndUpdateSimulator(payload, state) {
      const billets = [...state.billets, payload];
      const amountAll = billets.reduce((p, c) => p + Number(c.amount), 0);

      dispatch.billets.addBillet(payload);
      dispatch.simulator.updateSimulator({
        description: dispatch.simulator.description ?? '',
        original_amount: amountAll,
        payment_type: {},
        installment_amount: 0,
        total_with_interest: 0,
      });
    },

    removeBilletAndUpdateSimulator(billet, state) {
      const billets = state.billets.filter((billetData) => billetData.line !== billet.line);
      const amountAll = billets.reduce((p, c) => p + Number(c.amount), 0);

      dispatch.billets.removeBillet(billet);
      dispatch.simulator.updateSimulator({
        description: dispatch.simulator.description ?? '',
        original_amount: amountAll,
        payment_type: {},
        installment_amount: 0,
        total_with_interest: 0,
      });
    },
  }),
};
