import React, { useState } from 'react'
import { Header, List, Radio, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledHeader = styled(Header)`
  &&& {
    color: #22365f;
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
  }
`;

const PaddedSpan = styled.span`
  padding: 0.5em;
`;

const AccountTypeFields = (props) => {
  const [value, setValue] = useState('business');

  const onChange = (e, state) => {
    setValue(state.value);
    props.onChange(e, state);
  };

  return (
    <>
      <StyledHeader>
        Formulário de Credenciamento
      </StyledHeader>
      <Segment.Group>
        <Segment>
          Para realizar o seu credenciamento escolha a forma como deseja se cadastrar.
          Se optar em receber em conta de pessoa jurídica, preencha somente o formulário "Pessoa Jurídica".
          Se optar em receber em sua conta de pessoa física, preencha somente o formulário "Pessoa Física".
        </Segment>
        <Segment>
          <List horizontal>
            <List.Item>
              <label htmlFor={"business"}>
                <Radio id="business" name="account" value="business"
                  checked={value === "business"} onChange={onChange} />
                <PaddedSpan>Quero receber as minhas transações em conta de <b>Pessoa Jurídica</b></PaddedSpan>
              </label>
            </List.Item>
            <List.Item>
              <label htmlFor={"individual"}>
                <Radio id="individual" name="account" value="individual"
                  checked={value === "individual"} onChange={onChange} />
                <PaddedSpan>Quero receber as minhas transações em conta de <b>Pessoa Física</b></PaddedSpan>
              </label>
            </List.Item>
          </List>
        </Segment>
      </Segment.Group>
    </>
  );
}

export default AccountTypeFields;
