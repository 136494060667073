import * as Api from './api';

export const authenticate = async (email, password) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID
  };

  const data = {
    email, password
  };

  const response = await Api.doRequest('/login', 'POST', headers, data);

  return response.data;
};

export const resetPassword = async (email) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID
  };

  const data = { email };

  const response = await Api.doRequest('/reset-password', 'POST', headers, data);

  return response.data;
};
