/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from "react";

import Card from "../../containers/Card";
import Title from "../../components/Title";
import IconButton from "../../components/IconButton";

import {
  Table,
  Grid,
  Button,
  Modal,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { maskMoney, maskDate } from "../../services/masks";
import {
  getPaymentTypes,
  selectList as getPaymentTypeOptions,
} from "../../services/api/paymentTypes";

import { PrintedReceipt } from "./Receipt";

import { connect } from "react-redux";
import {
  list as listSales,
  updateList as updateListSales,
} from "../../services/api/sales";
import { CSVLink } from "react-csv";

import ReactToPrint from "react-to-print";

import moment from "moment-timezone";
import styled from "styled-components";

import * as Storage from "../../services/storage";
import CouponReceipt from "./CouponReceipt";
import { successNotification } from "../../services/notification";

const TitleContainer = styled.div`
  padding: 0;
  margin-top: -1em;
  background: transparent;
`;

const statusColors = {
  pending: "warning",
  failure: "negative",
  payed: "positive",
  expired: "negative",
};

const checkoutStatusMap = {
  pending: "Pendente",
  payed: "Pago",
  failure: "Falha",
  expired: "Expirado",
};

const statusOptions = [
  {
    key: 1,
    value: "failure",
    text: "Falhado",
  },
  {
    key: 2,
    value: "payed",
    text: "Pago",
  },
  {
    key: 3,
    value: "pending",
    text: "Pendente",
  },
  {
    key: 4,
    value: "expired",
    text: "Expirado",
  },
];

const filterOptions = [
  {
    key: 0,
    value: "payment_type_description",
    text: "Forma de Pagamento",
  },
  {
    key: 1,
    value: "status",
    text: "Status",
  },
  {
    key: 2,
    value: "from_to_date",
    text: "De / Até",
  },
];

const ReceiptModal = ({ toggle, sale, isOpen }) => {
  let componentRef = useRef();
  let couponReceiptRef = useRef();

  return (
    <>
      <Modal open={isOpen}>
        <Modal.Header>Segunda via de recibo</Modal.Header>
        <Modal.Content scrolling>
          <PrintedReceipt sale={sale} ref={componentRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={toggle}>
            Fechar
          </Button>
          <ReactToPrint
            trigger={() => <Button positive>Imprimir</Button>}
            content={() => componentRef.current}
          />
          <ReactToPrint
            trigger={() => <Button secondary>Imprimir Cupom</Button>}
            content={() => couponReceiptRef.current}
          />
          <div style={{ display: "none" }}>
            <CouponReceipt data={sale} ref={couponReceiptRef} />
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SalesList = ({ dispatch, history }) => {
  const [modalIsOpen, toggleModal] = useState(false);
  const [sales, setSales] = useState([]);
  const [currentSale, setCurrentSale] = useState({});

  const [csvSalesData, setCsvSalesData] = useState([]);
  const [csvSalesHeaders, setCsvSalesHeaders] = useState([]);

  const [limit] = useState(40);

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [query, setQuery] = useState(null);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions.find((filter) => filter.key === 0)
  );

  const openReceipt = (sale) => {
    setCurrentSale(sale);
    toggleModal(!modalIsOpen);
  };

  const getCsvSalesData = (salesData) =>
    salesData.map((saleData) => {
      let parsed_date;

      try {
        parsed_date = moment(saleData.created_at.toDate()).tz(
          "America/Sao_Paulo"
        );
      } catch (e) {}

      return {
        payer_name: saleData.payer.name,
        payment_type: `${saleData.payment_type.description}`,
        created_at: parsed_date
          ? `${parsed_date.format("DD/MM/YYYY HH:mm")}`
          : "",
        original_amount: `R$ ${maskMoney(
          saleData.original_amount / 100,
          true
        )}`,
      };
    });

  useEffect(() => {
    (async () => {
      dispatch.loading.isLoading(true);

      setCsvSalesHeaders([
        { label: "Pagador", key: "payer_name" },
        { label: "Forma de Pagamento", key: "payment_type" },
        { label: "Data de Criação", key: "created_at" },
        { label: "Total", key: "original_amount" },
      ]);

      const tenantId = Storage.get("uid");
      const selectedOwner = Storage.get("selected_owner");

      const salesData = await listSales(
        tenantId,
        selectedOwner,
        limit,
        "?type=billets"
      );
      const parsedSalesData = salesData.filter(
        (sale) => sale.created_at && null !== sale.created_at
      );

      setSales(parsedSalesData);

      const getPaymentTypesResponse = await getPaymentTypes(
        tenantId,
        selectedOwner
      );

      setPaymentTypeOptions(getPaymentTypeOptions(getPaymentTypesResponse));

      dispatch.loading.isLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query === null) return;
    (async () => {
      try {
        dispatch.loading.isLoading(true);

        const tenantId = Storage.get("uid");
        const selectedOwner = Storage.get("selected_owner");

        const salesData = await listSales(
          tenantId,
          selectedOwner,
          limit,
          "?type=billets" + query
        );

        const parsedSalesData = salesData.filter(
          (sale) => sale.created_at && null !== sale.created_at
        );

        setSales(parsedSalesData);
      } catch (e) {
        console.log(e);
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, limit]);

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Button
              icon="plus"
              primary
              floated="right"
              title="Nova venda"
              onClick={() => history.push("/admin/billets/validate")}
            />
            <CSVLink
              data={csvSalesData}
              target="_blank"
              filename={"lista-de-vendas.csv"}
              separator={";"}
              enclosingCharacter={`'`}
              headers={csvSalesHeaders}
              onClick={(event) => {
                setCsvSalesData(getCsvSalesData(sales));

                return true;
              }}
            >
              <Button
                icon="file excel"
                {...(sales.length === 0 && { disabled: true })}
                secondary
                floated="right"
                title="Exportar para CSV"
              />
            </CSVLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <TitleContainer>
        <Title tag="h3">Boletos</Title>
      </TitleContainer>
      <Card>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <label>Filtros</label>
              <div>
                <Dropdown
                  selection
                  search
                  fluid
                  value={selectedFilter.value}
                  onChange={(e, data) => {
                    setSelectedFilter(
                      filterOptions.find(
                        (filter) => filter.value === data.value
                      )
                    );
                    setFromDateFilter("");
                    setToDateFilter("");
                  }}
                  options={filterOptions}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}>
              {selectedFilter.value === "from_to_date" && (
                <>
                  <Grid stackable>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>De</label>
                      <div>
                        <DateInput
                          name="from_date_filter"
                          iconPosition="left"
                          value={fromDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            if (value === "") {
                              setFromDateFilter("");
                              setToDateFilter("");

                              setQuery("");
                              return;
                            }
                            const date = maskDate(value);
                            const fromDate =
                              date !== ""
                                ? moment(date.split("/").reverse().join("-"))
                                    .startOf("day")
                                    .toDate()
                                : "";
                            const toDate =
                              toDateFilter !== ""
                                ? moment(
                                    toDateFilter.split("/").reverse().join("-")
                                  )
                                    .endOf("day")
                                    .toDate()
                                : "";

                            setFromDateFilter(date);

                            const fromDateConverted = fromDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            const toDateConverted = toDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            if (toDate !== "") {
                              setQuery(
                                "&from=" +
                                  fromDateConverted +
                                  "&" +
                                  "to=" +
                                  toDateConverted
                              );

                              return;
                            }

                            setQuery("&from=" + fromDateConverted);
                            return;
                          }}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                      <label>Até</label>
                      <div>
                        <DateInput
                          name="to_date_filter"
                          iconPosition="left"
                          value={toDateFilter}
                          readOnly={!fromDateFilter}
                          minDate={fromDateFilter}
                          clearable
                          fluid
                          onChange={(e, { value }) => {
                            if (value === "") {
                              setToDateFilter("");
                              setQuery("");
                              return;
                            }

                            const date = maskDate(value);
                            const fromDate =
                              fromDateFilter !== ""
                                ? moment(
                                    fromDateFilter
                                      .split("/")
                                      .reverse()
                                      .join("-")
                                  )
                                    .startOf("day")
                                    .toDate()
                                : "";
                            const toDate =
                              date !== ""
                                ? moment(date.split("/").reverse().join("-"))
                                    .endOf("day")
                                    .toDate()
                                : "";
                            setToDateFilter(date);

                            const fromDateConverted = fromDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            const toDateConverted = toDate.toLocaleString(
                              "en-US",
                              {
                                timeZone: "America/New_York",
                                hour12: false,
                                weekday: "short",
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }
                            );

                            if (fromDateFilter !== "") {
                              setQuery(
                                "&from=" +
                                  fromDateConverted +
                                  "&" +
                                  "to=" +
                                  toDateConverted
                              );

                              return;
                            }
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid>
                </>
              )}
              {selectedFilter.value === "payment_type_description" && (
                <>
                  <label>Formas de Pagamento</label>
                  <div>
                    <Dropdown
                      selection
                      fluid
                      clearable
                      onChange={(e, { value, options }) => {
                        if (value === "") return setQuery("");

                        const { text } = options.find(
                          (val) => val.value === value
                        );
                        setQuery("&" + selectedFilter.value + "=" + text);
                        return;
                      }}
                      options={paymentTypeOptions}
                    />
                  </div>
                </>
              )}
              {selectedFilter.value === "status" && (
                <>
                  <label>Status</label>
                  <div>
                    <Dropdown
                      selection
                      search
                      fluid
                      clearable
                      onChange={(e, { value, options }) => {
                        if (value === "") return setQuery("");

                        const option = options.find(
                          (val) => val.value === value
                        );

                        setQuery(
                          "&" + selectedFilter.value + "=" + option.value
                        );
                        return;
                      }}
                      options={statusOptions}
                    />
                  </div>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      {sales.length > 0 ? (
        <>
          <Table celled striped role="grid" aria-labelledby="header">
            <Table.Header
              style={{
                display: "table",
                width: "100%",
                tableLayout: "fixed",
                paddingRight: "10px",
              }}
            >
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  Descrição
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Pagador</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Data de Criação
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Valor do Boleto
                </Table.HeaderCell>
                <Table.HeaderCell width={1} style={{ fontSize: 12 }} />
              </Table.Row>
            </Table.Header>
            <Table.Body
              style={{
                display: "block",
                overflow: "auto",
                maxHeight: "57vh",
                width: "100%",
              }}
              onScroll={(event) => {
                if (
                  query?.split("?")[1]?.split("=")[0] !==
                  "payment_type_description"
                )
                  return;
                const bottom =
                  event.target.scrollHeight - event.target.scrollTop ===
                  event.target.clientHeight;
                if (bottom) {
                  (async () => {
                    try {
                      dispatch.loading.isLoading(true);

                      const offset = sales[sales.length - 1].sale_id;
                      const tenantId = Storage.get("uid");
                      const selectedOwner = Storage.get("selected_owner");
                      const updatedList = await updateListSales(
                        tenantId,
                        selectedOwner,
                        limit,
                        offset,
                        query
                      );

                      if (updatedList.length > 0) {
                        setSales([...sales, ...updatedList]);
                        return;
                      }
                    } catch (e) {
                      console.log(e);
                    } finally {
                      dispatch.loading.isLoading(false);
                    }
                  })();
                }
              }}
            >
              {sales.map((sale, index) => {
                const parsed_date = moment(sale.created_at).tz(
                  "America/Sao_Paulo"
                );

                const { checkout, payer, original_amount, billets } = sale;
                const { name } = payer;

                const description = billets && billets[0].description;

                const url = checkout?.url ?? "";
                const status = checkout?.status ?? "";

                return (
                  <Table.Row
                    key={index}
                    style={{
                      display: "table",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <Table.Cell textAlign="center">{description}</Table.Cell>
                    <Table.Cell
                      textAlign="center"
                      className={status && statusColors[status]}
                    >
                      {status && checkoutStatusMap[status]}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{name}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {parsed_date &&
                        `${parsed_date.format(
                          "DD/MM/YYYY"
                        )} às ${parsed_date.format("HH:mm:ss")}`}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      R$ {`${maskMoney(original_amount, true)}`}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {status === "pending" && (
                        <IconButton
                          title="Url de checkout"
                          name="copy"
                          onClick={() => {
                            navigator.clipboard.writeText(url);
                            successNotification("", "Link copiado.");
                          }}
                        />
                      )}
                      {status && status === "payed" && (
                        <IconButton
                          title="Segunda via do Recibo"
                          name="file alternate"
                          onClick={() => openReceipt(sale)}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <ReceiptModal
            isOpen={modalIsOpen}
            sale={currentSale}
            toggle={() => toggleModal(!modalIsOpen)}
          />
        </>
      ) : (
        <Message negative>
          <p>Registros não encontrados</p>
        </Message>
      )}
    </>
  );
};

export default connect()(SalesList);
