import * as Api from './api';

export const getData = async (tenantId, ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const res = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}`, 'GET', headers);

  return res.data;
};

export const setData = async (tenant, ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  tenant = Object.assign({}, tenant);
  tenant.covenant = tenant.covenant_other || tenant.covenant;
  tenant.bank_account.routing_number = `${tenant.bank_account.routing_number}${tenant.bank_account.routing_number_digit}`;
  tenant.bank_account.account_number = `${tenant.bank_account.account_number}${tenant.bank_account.account_number_digit}`;

  delete tenant.covenant_other;
  delete tenant.bank_account.routing_number_digit;
  delete tenant.bank_account.account_number_digit;

  const res = await Api.doRequest(`/owners/${ownerId}/tenants`, 'POST', headers, tenant);

  return res.data;
};

export const changePassword = async (tenantId, tenant, ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const res = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/change-password`, 'POST', headers, tenant);

  return res.data;
}
