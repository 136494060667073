import React, { useState, useEffect, Fragment } from 'react';

import { Formik } from 'formik';
import { Grid, Header, Divider, Radio, Responsive } from 'semantic-ui-react';
import { maskMoney } from '../services/masks';
import { calculateInstallmentAmount } from '../services/calculator';

import ErrorMessage from './ErrorMessage';
import Card from '../containers/Card';
import styled from 'styled-components';

const RowWithLineHeight = styled(Grid.Row)`
  line-height: 3em;
`;

const formatPaymentTypeText = (amount, customer_onlending_fee, payment_type) =>
  `${payment_type.installments} x R$ ${maskMoney(
    calculateInstallmentAmount(amount, customer_onlending_fee, payment_type),
    true
  )}`;

const defineZebraStyle = (index) => (index % 4 === 2 || index % 4 === 3) && { style: { backgroundColor: '#eee' } };

const Simulator = (props) => {
  const { payment_types } = props;

  let { simulator, typeSelectionHandler, original_amount } = props;

  original_amount = original_amount || simulator.original_amount;

  const [paymentType, setPaymentType] = useState({});

  useEffect(() => {
    if (simulator.payment_type !== undefined) {
      setPaymentType(simulator.payment_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik initialValues={simulator} enableReinitialize>
        {({ errors }) => (
          <Responsive fireOnMount as={Card}>
            <Grid stackable>
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h3'>Formas de Pagamento</Header>
                        <Divider clearing />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                {errors.paymentType && <ErrorMessage>{errors.paymentType}</ErrorMessage>}
                <Grid.Row>
                  <Grid.Column>
                    <Grid>
                      <RowWithLineHeight>
                        {payment_types
                          .sort((objOne, objTwo) => objTwo.id - objOne.id)
                          .map((paymentTypeData, index) => {
                            return (
                              <Fragment key={paymentTypeData.id.toString()}>
                                <Grid.Column computer={1} tablet={2} mobile={2} {...defineZebraStyle(index)}>
                                  <Radio
                                    name='option'
                                    onChange={(e) => {
                                      if (typeof typeSelectionHandler === 'function') {
                                        typeSelectionHandler(paymentTypeData);
                                        setPaymentType(paymentTypeData);
                                      }
                                    }}
                                    checked={paymentTypeData.id === paymentType.id}
                                  />
                                </Grid.Column>
                                <Grid.Column computer={3} tablet={7} mobile={7} {...defineZebraStyle(index)}>
                                  {paymentTypeData.description}
                                </Grid.Column>
                                <Grid.Column computer={4} tablet={7} mobile={7} {...defineZebraStyle(index)}>
                                  {formatPaymentTypeText(
                                    original_amount,
                                    paymentTypeData.customer_onlending_fee,
                                    paymentTypeData
                                  )}
                                </Grid.Column>
                              </Fragment>
                            );
                          })}
                      </RowWithLineHeight>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </>
            </Grid>
          </Responsive>
        )}
      </Formik>
    </>
  );
};

export default Simulator;
