import axios from 'axios';

export const request = async (url, method, headers, data) => {
  headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    ...headers
  };

  const config = {
    url,
    method,
    headers,
    crossdomain: true
  };

  if (data) {
    config.data = JSON.stringify(data);
  }
  let response;

  try {
    response = await axios(config);

    if (response.status >= 400) {
      throw response;
    }
  } catch (e) {
    throw Object.assign({}, e, response);
  }

  return response;
};

export const get = async (url, headers) => {
  let response;

  try {
    response = await request(url, 'GET', headers);
  } catch (e) {
    throw Object.assign({}, e, response);
  }

  return response;
};

export const post = async (url, headers, data) => {
  let response;

  try {
    response = await request(url, 'POST', headers, data);
  } catch (e) {
    throw Object.assign({}, e, response);
  }

  return response;
};
