import React from 'react';

import { ErrorMessage, Field } from 'formik';
import { Divider, Grid, Header } from 'semantic-ui-react';

import styled from 'styled-components';

import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';

import { covenants } from '../../services/covenants';
import { maskCpf, maskPhoneNumber } from '../../services/masks';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
`;

const IndividualFields = (props) => {
  const { setFieldValue } = props;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            Dados pessoais
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>Razão Social ou Nome Fantasia</label>
          <Field name="description" type="text" component={Input} fluid />
          <Error name="description" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>Nome do oficial</label>
          <Field name="first_name" type="text" component={Input} fluid
            onChange={async (e) => {
              const first_name = e.target.value;
              const last_name = props.values.last_name;

              setFieldValue('first_name', e.target.value);
              setFieldValue('bank_account.holder_name', `${first_name} ${last_name}`);
            }}
          />
          <Error name="first_name" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>Sobrenome</label>
          <Field name="last_name" type="text" component={Input} fluid
            onChange={async (e) => {
              const first_name = props.values.first_name;
              const last_name = e.target.value;

              setFieldValue('last_name', e.target.value);
              setFieldValue('bank_account.holder_name', `${first_name} ${last_name}`);
            }}
          />
          <Error name="last_name" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <label>CPF</label>
          <Field name="document" type="text" maxLength="14" component={Input} fluid
            onChange={(e) => {
              setFieldValue('document', maskCpf(e.target.value))
              setFieldValue('bank_account.document', maskCpf(e.target.value))
            }}
          />
          <Error name="document" component="div" />
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Telefone</label>
          <Field name="phone" type="text" maxLength="15" component={Input} fluid
            onChange={(e) => {
              setFieldValue('phone', maskPhoneNumber(e.target.value));
            }}
          />
          <Error name="phone" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>E-Mail</label>
          <Field name="email" type="text" component={Input} fluid />
          <Error name="email" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <label>Instituição Associada</label>
          <Field name="covenant" type="text" component={Dropdown} options={covenants} fluid />
          <Error name="covenant" component="div" />
        </Grid.Column>
        <Grid.Column width={8}>
          <label>Insira a instituição associada</label>
          <Field name="covenant_other" type="text" component={Input} disabled={props.values.covenant !== 'Outros'} fluid />
          <Error name="covenant_other" component="div" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default IndividualFields;
