export const covenants = [
  {
    key: 'CNB',
    value: 'CNB',
    text: 'CNB'
  },
  {
    key: 'Colégio de Registro Civil',
    value: 'Colégio de Registro Civil',
    text: 'Colégio de Registro Civil'
  },
  {
    key: 'CORI',
    value: 'CORI',
    text: 'CORI'
  },
  {
    key: 'IEPTB',
    value: 'IEPTB',
    text: 'IEPTB'
  },
  {
    key: 'IRTDPJ',
    value: 'IRTDPJ',
    text: 'IRTDPJ'
  },
  {
    key: 'RECIVIL ',
    value: 'RECIVIL ',
    text: 'RECIVIL '
  },
  {
    key: 'Não sou associado',
    value: 'Não sou associado',
    text: 'Não sou associado'
  },
  {
    key: 'Outros',
    value: 'Outros',
    text: 'Outros'
  }
];
