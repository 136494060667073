import React from 'react';
import { connect } from 'react-redux';

import { Formik, Field } from 'formik';
import { Grid, Header, Divider, Responsive } from 'semantic-ui-react';

import { SendButton } from '../../components/SendButton';
import Input from '../../components/Input';
import Card from '../../containers/Card';
import AddressForm from '../../components/AddressForm';

import { maskCpfOrCnpj } from '../../services/masks';
import { save as saveSale } from '../../services/api/sales';
import { round } from '../../services/math';
import * as Storage from '../../services/storage';

const PersonalInfoFields = (props) => {
  const { setFieldValue } = props;

  return (
    <>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>Nome</label>
          <div>
            <Field name='name' type='text' component={Input} fluid />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>CPF/CNPJ</label>
          <div>
            <Field
              name='document'
              type='text'
              onChange={(e) => {
                setFieldValue('document', maskCpfOrCnpj(e.target.value));
              }}
              component={Input}
              fluid
            />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>RG</label>
          <div>
            <Field name='national_id_card' type='text' component={Input} fluid />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <label>E-Mail</label>
          <div>
            <Field name='email' type='email' component={Input} fluid />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Telefone</label>
          <div>
            <Field name='phone' type='phone' component={Input} fluid />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>Endereço</Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const PayerForm = (props) => {
  const { dispatch, simulator } = props;
  const { payment_type } = simulator;

  let { payer } = props;

  if (!Boolean(Object.keys(payer)[0])) {
    payer = {
      name: '',
      document: '',
      national_id_card: '',
      phone: '',
      email: '',
      user_id: '',
      address: {
        postal_code: '',
        street: '',
        district: '',
        number: '',
        complement: '',
        state: '',
        city: '',
      },
    };
  }

  const buildSale = (payer) => {
    const total_with_interest = parseFloat(simulator.total_with_interest);
    const original_amount = simulator.original_amount;
    const parcela_amount = round(total_with_interest - original_amount, 2);

    const payee = {
      name: Storage.get('description'),
      address: JSON.parse(Storage.get('tenant_address')),
      phone: Storage.get('tenant_phone'),
      document: Storage.get('tenant_document'),
    };

    return {
      type: 'transaction',
      description: simulator.description,
      original_amount,
      payment_type,
      installment_amount: simulator.installment_amount,
      total_with_interest: simulator.total_with_interest,
      payee: {
        ...payee,
      },
      payer: {
        address: {
          ...payer.address,
        },
        document: payer.document,
        email: payer.email,
        name: payer.name,
        national_id_card: payer.national_id_card,
        phone: payer.phone,
      },
      split_rules: {
        original_amount,
        total_with_interest,
        parcela_amount,
      },
    };
  };

  return (
    <>
      <Formik
        initialValues={payer}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          const sale = buildSale(values);
          const selectedOwner = Storage.get('selected_owner');

          try {
            dispatch.loading.isLoading(true);

            const tenantId = Storage.get('uid');
            const saleResponse = await saveSale(tenantId, selectedOwner, sale);

            dispatch.payer.updatePayer(values);
            dispatch.sale.createSale({ ...sale, id: saleResponse.id });

            props.history.push('/admin/sales/receipt');
          } catch (e) {
            console.log(e);
          } finally {
            dispatch.loading.isLoading(false);
          }
        }}
        render={(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <SendButton />
            <Responsive fireOnMount as={Card}>
              <Grid stackable>
                <PersonalInfoFields {...formProps} />
                <AddressForm {...formProps} />
              </Grid>
            </Responsive>
          </form>
        )}
      ></Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  payer: state.payer,
  simulator: state.simulator,
});

export default connect(mapStateToProps)(PayerForm);
