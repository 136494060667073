import { round } from './math';

const interestCoefficient = (i, n) => (i / 100) / (1 - (1 / ((1 + (i / 100)) ** n)));

const calcEMI = (amount, interestRate, installments) => {
  const coefficient = interestCoefficient(interestRate, installments);

  return amount * coefficient;
};

export const calculateInstallmentAmount = (amount, fee, paymentType) => {
  const isCredit = paymentType.type === 'credit';
 
  if(isCredit) {
    return calcEMI(amount, fee, paymentType.installments);
  }
 
  return amount / (1 - (fee / 100))/paymentType.installments;
};

export const calculateAmount = (amount, fee, payment_type) => {
  return round(calculateInstallmentAmount(amount, fee, payment_type) * payment_type.installments, 2);
};