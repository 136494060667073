import * as Api from './api';

export const validate = async (ownerId, tenantId, barcode) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`,
  };

  const response = await Api.doRequest(`/owners/${ownerId}/tenants/${tenantId}/barcode/${barcode}`, 'GET', headers);

  return response.data;
};
