const moment = require('moment-timezone');
const Holidays = require("date-holidays");
const holidays = new Holidays("BR").getHolidays();

export const getDateRange = (date) => {
  return Math.floor(new Date(`${date} UTC`).getTime() / 1000);
};

export const getSubtractedDate = (date, unit, amount) => {
  return moment(date).subtract(amount, unit).toDate();
};

export const getTodayDate = () => {
  return moment().utc().startOf('day').toDate();
};

export const isToday = (_date) => {
  const date = new Date(_date)
  const today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
}


export const getUTCDate = () => {
  return moment().utc().toDate();
};

export const getNextBusinessDay = (date) => {
  if (holidays.length < 0) return;

  const tomorrow = new Date(
    new Date(date).setDate(new Date(date).getDate() + 1)
  );

  const day = tomorrow.getDay();

  if (day !== 0 && day !== 6) {
    const isHoliday = holidays
      .map(({ start, end }) => {
        if (tomorrow < start || tomorrow > end) return false;
        return true;
      })
      .some((holiday) => holiday);

    if (!isHoliday) return tomorrow;
  }
  return getNextBusinessDay(tomorrow);
};