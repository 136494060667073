import React, { useState } from "react";

import {
  Button,
  Grid,
  Header,
  Modal,
  Responsive,
  Form,
} from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";

import Billets from "@ledstartupstudio/boleto";

import * as Storage from "../../services/storage";
import { validate } from "../../services/api/billets";
import { dangerNotification } from "../../services/notification";
import { round } from "../../services/math";
import {
  maskMoney,
  maskBilletNumber,
  unmaskBilletNumber,
  maskBankBilletNumber,
  maskTributeBilletNumber,
} from "../../services/masks";

const Text = styled(Header)`
  font-weight: normal;
  padding-top: 0.5em;
`;

const BilletsModal = (props) => {
  const { toggle, addAction, billets } = props;
  const isOpen = props.isOpen || false;

  const selectedOwner = Storage.get("selected_owner");
  const tenantId = Storage.get("uid");

  const [loading, setLoading] = useState(false);
  const [digitableLine, setDigitableLine] = useState("");
  const [description, setDescription] = useState("");

  const [billet, setBillet] = useState({
    amount: Number(0),
    due_date: undefined,
    isValid: false,
    line: "",
    barcode: "",
  });

  const resetBillet = () => {
    setBillet({
      amount: Number(0),
      isValid: false,
      due_date: undefined,
      line: "",
      barcode: "",
    });
  };

  const validateBillet = async (line) => {
    if (billets.some((s) => s.line === line)) {
      dangerNotification("Atenção", "Essa linha digitável já foi inserida!");
      return;
    }

    try {
      let validateResponse = await validate(selectedOwner, tenantId, line);
      let {
        type,
        bar_code,
        amount,
        due_date,
        valid_window,
        payment_opening_schedule,
        payment_closing_schedule,
      } = validateResponse;

      const today = new Date();
      const parsedDueDate = new Date(
        Date.parse(`${due_date}T00:00:00.000-03:00`)
      );

      if (!(type === "normal" || type === "registered")) {
        dangerNotification("Atenção", "Este boleto não é válido!");
        return;
      } else if (parsedDueDate.getTime() < today.getTime()) {
        dangerNotification("Atenção", "Este boleto está vencido!");
        return;
      } else if (
        parsedDueDate.getMonth() === today.getMonth() &&
        parsedDueDate.getDate() === today.getDate() &&
        !valid_window
      ) {
        dangerNotification(
          "Atenção",
          "Este boleto está fora da janela de pagamento!"
        );
        return;
      }

      setBillet({
        isValid: true,
        type: type,
        line: line,
        barcode: bar_code,
        amount: Number(amount),
        due_date: due_date,
        valid_window: valid_window,
        payment_opening_schedule: payment_opening_schedule,
        payment_closing_schedule: payment_closing_schedule,
      });
    } catch (e) {
      console.log("Error parsing billet", e);
      dangerNotification("Atenção", "Este boleto não é válido!");
      return;
    }
  };

  const parseDigitableLine = async (line) => {
    setLoading(true);
    if (Billets.isValid(line)) {
      if (line.length === 44) {
        line = Billets.parse(line).toLine();
        setDigitableLine(
          line.length === 47
            ? maskBankBilletNumber(line)
            : maskTributeBilletNumber(line)
        );
      }
      await validateBillet(line);
    } else {
      resetBillet();
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    if (billet.isValid) {
      addAction({
        line: billet.line,
        barcode: billet.barcode,
        amount: billet.amount,
        due_date: billet.due_date,
        description: description,
      });
    }
  };

  return (
    <>
      <Form.Field onSubmit={handleSubmit}>
        <Modal open={isOpen}>
          <Modal.Header>Insira os dados do débito</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Responsive fireOnMount>
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <label>Linha digitável (código de barras)*</label>
                      <div>
                        <Form.Input
                          fluid
                          loading={loading}
                          disabled={loading}
                          type="text"
                          value={digitableLine}
                          onChange={(e) => {
                            var masked = maskBilletNumber(e.target.value);
                            var unmasked = unmaskBilletNumber(masked);
                            if (digitableLine !== masked) {
                              setDigitableLine(masked);
                              parseDigitableLine(unmasked);
                            }
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <label>Descrição</label>
                      <div>
                        <Form.Input
                          name="description"
                          type="text"
                          fluid
                          onChange={(e) => setDescription(e.target.value)}
                        ></Form.Input>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <label>Data de vencimento</label>
                      <div>
                        <Text as="h4">
                          <strong>{`${
                            billet.due_date
                              ? moment(billet.due_date, "YYYY-MM-DD").format(
                                  "DD/MM/YYYY"
                                )
                              : "__/__/____"
                          }`}</strong>
                        </Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <label>Valor</label>
                      <div>
                        <Text as="h4">
                          <strong>{`R$ ${maskMoney(
                            round(billet.amount, 2),
                            true
                          )}`}</strong>
                        </Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Responsive>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => {
                setDigitableLine("");
                resetBillet();
                toggle();
              }}
            >
              Cancelar
            </Button>
            <Button positive onClick={handleSubmit}>
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
      </Form.Field>
    </>
  );
};

export default BilletsModal;
