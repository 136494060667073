import { init } from "@rematch/core";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { createBrowserHistory } from "history";
import createPersistPlugin from "@rematch/persist";

import models from "../models";

export const history = createBrowserHistory();

const persistPlugin = createPersistPlugin({
  version: 2,
  whitelist: ['user'],
});

const reducers = { router: connectRouter(history) };

const store = init({
  models,
  plugins: [persistPlugin],
  redux: {
    reducers,
    middlewares: [routerMiddleware(history)],
    devtoolOptions: {},
    rootReducers: {
      RESET: (state, action) => undefined,
    },
  },
});

export default store;
