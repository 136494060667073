import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Responsive, Grid } from 'semantic-ui-react';
import { calculateAmount, calculateInstallmentAmount } from '../../services/calculator';
import { getPaymentTypes } from '../../services/api/paymentTypes';

import Input from '../../components/Input';
import Card from '../../containers/Card';
import styled from 'styled-components';
import Simulator from '../../components/Simulator';

import * as Storage from '../../services/storage';

import * as math from 'mathjs';
import SimulatorStatistics from '../../components/SimulatorStatistics';

const InputWrapper = styled.div`
    padding: 1em;
`;

const QuickSimulatorForm = (props) => {
  const { dispatch } = props;

  const [payment_types, setPaymentTypes] = useState([]);

  useEffect(() => {
    (async() => {
      dispatch.loading.isLoading(true);

      const tenantId = Storage.get('uid');
      const selectedOwner = Storage.get('selected_owner');
      const getPaymentTypesResponse = await getPaymentTypes(tenantId, selectedOwner);

      setPaymentTypes(getPaymentTypesResponse);

      dispatch.loading.isLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [simulator, setSimulator] = useState({
    original_amount: 0,
    payment_type: {},
    total_with_interest: 0,
    installment_amount: 0
  });

  const updateSimulator = (s) => {
    let total_with_interest = calculateAmount(
      s.original_amount,
      s.payment_type.customer_onlending_fee,
      s.payment_type
    );

    let installment_amount = calculateInstallmentAmount(
      s.original_amount,
      s.payment_type.customer_onlending_fee,
      s.payment_type
    )

    setSimulator({
      original_amount: s.original_amount,
      payment_type: s.payment_type,
      total_with_interest: (isNaN(total_with_interest) ? 0 : total_with_interest),
      installment_amount: (isNaN(installment_amount) ? 0 : installment_amount),
    });
  };

  const handlePaymentTypeChange = (selectedPaymentType) => {
    let s = simulator;
    s.payment_type = selectedPaymentType;

    updateSimulator(s);
  };

  return (
      <>
        <Responsive fireOnMount as={Card}>
          <InputWrapper>
            <h3>Valor Original dos Serviços:</h3>
            <Input name="amount"
              type="text"
              fluid
              placeholder="Ex.: 123,52 + 64,32 + ..."
              onChange={(e) => {
                let s = simulator;
                try {
                  let value = e.target.value.replace(/,/gi, '.');

                  if (value === '') {
                    value = '0';
                  }
                  s.original_amount = Number(math.evaluate(value));

                  updateSimulator(s);
                } catch (e) { }
              }}
            />
          </InputWrapper>
          <Grid stackable>
            <>
              <SimulatorStatistics original_amount={simulator.original_amount}
                installments={simulator.payment_type.installments}
                installment_amount={simulator.installment_amount}
                total_with_interest={simulator.total_with_interest} />
              { payment_types && (
                <Simulator
                  payment_types={payment_types}
                  simulator={simulator}
                  typeSelectionHandler={handlePaymentTypeChange}
                  totalWithInterest={simulator.total_with_interest}
                  installmentAmount={simulator.installment_amount}/>
              )}
            </>
          </Grid>
        </Responsive>
    </>
  );
};

export default connect()(QuickSimulatorForm);

