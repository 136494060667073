import React from 'react'
import { Dropdown as SemanticDropDown } from 'semantic-ui-react';

const Dropdown = (props) => {
  const { field } = props;
  const { form: { setFieldValue, setFieldTouched } } = props;

  return (
    <>
      <SemanticDropDown
        {...field}
        {...props}
        selection
        clearable
        search
        onChange={(e, { value }) => setFieldValue(field.name, value)}
        onBlur={(e, { value }) => setFieldTouched(field.name, true, true)}
      />
    </>
  )
};

export default Dropdown;
