import * as Api from './api';

export const getData = async (ownerId) => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const response = await Api.doRequest(`/owners/${ownerId}`, 'GET', headers);

  return response.data;
};

export const listOwners = async () => {
  const headers = {
    alias: process.env.REACT_APP_PROJECT_ID,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  const response = await Api.doRequest('/owners', 'GET', headers);
  const { data } = response.data;

  return data.map(owner => {
    return {
      key: owner.id,
      value: owner.id,
      text: owner.description,
      termsOfService: owner.terms_of_service
    }
  });
};
