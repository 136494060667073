import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

export const SendButtonContainer = (props) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ButtonWrapper>
            {props.children}
          </ButtonWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);

export const SendButton = (props) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <ButtonWrapper>
            <Button primary type="submit" floated={props?.position || 'right'}>{props?.label || 'Salvar'}</Button>
          </ButtonWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);
