export const isAbleToSimulatorForm = (sale) => !Boolean(Object.keys(sale)[0]);

export const isAbleToPayerForm = (simulator, sale) =>
  Boolean(Object.keys(simulator)[0]) && !Boolean(Object.keys(sale)[0]);

export const isAbleToReceiptForm = (simulator, payer, sale) =>
  Boolean(Object.keys(simulator)[0]) && Boolean(Object.keys(payer)[0]) && Boolean(Object.keys(sale)[0]);

export const isBilletsAbleToPayerForm = (billets, simulator) => billets !== undefined && billets.length > 0;

export const isBilletsAbleToReceiptForm = (billets, simulator, payer, sale) =>
  isBilletsAbleToPayerForm(billets, simulator) && Boolean(Object.keys(payer)[0]) && Boolean(Object.keys(sale)[0]);
