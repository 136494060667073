import React from 'react';

import { Grid } from 'semantic-ui-react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Credentiation from './pages/credentiation/Credentiation';
import DocumentManager from './pages/adherence-monitoring/DocumentManager';
import CheckoutForm from './pages/checkout/CheckoutForm';

const App = ({ history }) => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Grid.Row>
        <Grid.Column>
          <Switch>
            <Route exact path='/' component={Login} />
            <ProtectedRoute path='/admin' component={Dashboard} />
            <Route exact path='/credentiation' component={Credentiation} />
            <Route path='/adherence-monitoring' component={DocumentManager} />
            <Route path='/checkout/:token' component={CheckoutForm} />
            <Redirect from='*' to='/' />
          </Switch>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default App;
