import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Holidays from "date-holidays";
import moment from "moment-timezone";

import { maskMoney, maskDate, formatAmount } from "../../services/masks";
import { list as listStatements } from "../../services/api/statements";
import * as Storage from "../../services/storage";

import { Table, Grid, Dropdown, Button, Icon } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import Header from "../../containers/Header";
import Card from "../../containers/Card";

import Visa from "../../assets/visa-black.svg";
import Elo from "../../assets/elo-black.svg";
import Amex from "../../assets/amex.svg";
import MasterCard from "../../assets/mastercard.svg";
import Maestro from "../../assets/maestro.svg";
import VisaElectron from "../../assets/visa-electron.svg";

import "./statement.css";

const paymentTypeOptions = [
  {
    key: 1,
    value: "boleto",
    text: "Boleto",
  },
  {
    key: 2,
    value: "credit",
    text: "Crédito",
  },
  {
    key: 3,
    value: "debit",
    text: "Débito",
  },
  {
    key: 4,
    value: "pix",
    text: "Pix",
  },
];

const statusOptions = [
  {
    key: 1,
    value: "failed",
    text: "Falhado",
  },
  {
    key: 2,
    value: "succeeded",
    text: "Aprovado",
  },
  {
    key: 3,
    value: "pending",
    text: "Pendente",
  },
  {
    key: 4,
    value: "canceled",
    text: "Cancelado",
  },
];

const cardOptions = {
  Visa,
  "Visa Electron": VisaElectron,
  Maestro,
  MasterCard,
  Elo,
  "American Express": Amex,
};

const typeOptions = {
  credit: "Crédito",
  boleto: "Boleto",
  debit: "Débito",
};

const statusColors = {
  pending: "warning",
  failed: "negative",
  succeeded: "positive",
  canceled: "negative",
};

const statusType = {
  pending: "Pendente",
  failed: "Falhado",
  succeeded: "Aprovado",
  canceled: "Cancelado",
};

const Statement = ({ dispatch, history }) => {
  const [oneDateFilter, setOneDateFilter] = useState("");
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [paymentTypeOption, setPaymentTypeOption] = useState("");
  const [query, setQuery] = useState({
    from_date: "",
    to_date: "",
    payment_type: "",
    status: "",
    date_down: "",
  });
  const [holidays, setHolidays] = useState([]);
  const [statements, setStatements] = useState([]);

  const [csvStatementsData, setCsvStatementsData] = useState([]);
  const [csvStatementsHeaders, setCsvStatementsHeaders] = useState([]);

  const formatedTotal = (type, isNetProfit) => {
    const arrValues = statements
      .map(({ amount, status, net_value }) => {
        if (status === type) {
          if (isNetProfit) return net_value;
          return Number(amount);
        }
        return false;
      })
      .filter(Boolean);

    if (arrValues.length === 0) return 0;

    const formatValues = arrValues.reduce((prev, next) => prev + next);

    return handleFormatAmout(formatValues);
  };

  const handleFormatAmout = (valueToFormat) => {
    const values = valueToFormat.toString().split(".");

    const centsExists = values[1] ? values[1] : "00";

    const formatCents =
      centsExists.length > 1 ? centsExists : centsExists + "0";

    const cents =
      formatCents.length < 2 ? formatCents : formatCents.slice(0, 2);

    const money = `${values[0]}.${cents}`;

    return formatAmount(money);
  };

  const getLastBusinessDay = (date) => {
    if (holidays.length < 0) return;

    const yesterday = new Date(
      new Date(date).setDate(new Date(date).getDate() - 1)
    );

    const day = yesterday.getDay();

    if (day !== 0 && day !== 6) {
      const isHoliday = holidays
        .map(({ start, end }) => {
          if (yesterday < start || yesterday > end) return false;
          return true;
        })
        .some((holiday) => holiday);

      if (!isHoliday) return yesterday;
    }
    return getLastBusinessDay(yesterday);
  };

  const getCsvStatementsData = (StatementData) =>
    StatementData.map(
      ({
        status,
        type,
        amount,
        net_value,
        card_brand,
        created_at,
        updated_at,
        id,
        installment_plan,
      }) => {
        const parsed_date = moment(created_at).tz("America/Sao_Paulo");
        const billet_date =
          paymentTypeOption === "boleto" &&
          moment(getLastBusinessDay(updated_at)).tz("America/Sao_Paulo");

        return {
          created_at: parsed_date
            ? `${parsed_date.format("DD/MM/YYYY HH:mm")}`
            : "",
          billet_date:
            billet_date && billet_date
              ? `${billet_date.format("DD/MM/YYYY HH:mm")}`
              : "",
          type,
          status,
          installment_plan: installment_plan
            ? installment_plan.number_installments
            : null,
          card_brand,
          net_profit: `R$ ${maskMoney(net_value, true)}`,
          gross_profit: `R$ ${maskMoney(amount, true)}`,
        };
      }
    );

  useEffect(() => {
    (async () => {
      try {
        dispatch.loading.isLoading(true);

        setCsvStatementsHeaders([
          { label: "Data de Criação", key: "created_at" },
          { label: "Data de Atualização", key: "billet_date" },
          { label: "Forma de Pagamento", key: "type" },
          { label: "Status", key: "status" },
          { label: "Parcelas", key: "installment_plan" },
          { label: "Cartão", key: "card_brand" },
          { label: "Valor Líquido", key: "net_profit" },
          { label: "Valor Bruto", key: "gross_profit" },
        ]);

        const { client_id } = JSON.parse(Storage.get("tenant_credentials"));

        setStatements(await listStatements(client_id, 0, query));

        const hd = new Holidays("BR");

        const thisYear = new Date().getFullYear();
        setHolidays(hd.getHolidays(thisYear));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        dispatch.loading.isLoading(true);
        const { client_id } = JSON.parse(Storage.get("tenant_credentials"));

        setStatements(await listStatements(client_id, 0, query));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch.loading.isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return (
    <>
      <Header
        title="Extrato"
        csvData={csvStatementsData}
        csvHeader={csvStatementsHeaders}
        setCsvData={setCsvStatementsData}
        getCsvData={getCsvStatementsData}
        data={statements}
      >
        <CSVLink
          data={csvStatementsData}
          target="_blank"
          filename={`lista-de-recibos-de-vendas.csv`}
          separator={";"}
          enclosingCharacter={`'`}
          headers={csvStatementsHeaders}
          onClick={(event) => {
            setCsvStatementsData(getCsvStatementsData(statements));
            return true;
          }}
        >
          <Button
            icon="file excel"
            {...(statements.length === 0 && { disabled: true })}
            secondary
            floated="right"
            title="Exportar para CSV"
          />
        </CSVLink>
      </Header>
      <Card>
        <Grid stackable>
          <Grid.Row>
            {paymentTypeOption !== "boleto" ? (
              <>
                <Grid.Column mobile={8} tablet={4} computer={4}>
                  <label>De</label>
                  <div>
                    <DateInput
                      name="from_date_filter"
                      iconPosition="left"
                      value={fromDateFilter}
                      clearable
                      fluid
                      onChange={(e, { value }) => {
                        const date = maskDate(value);

                        const fromDate =
                          date !== ""
                            ? moment(date.split("/").reverse().join("-"))
                                .startOf("day")
                                .toDate()
                            : "";

                        setFromDateFilter(date);
                        const fromDateConverted = fromDate.toLocaleString(
                          "en-US",
                          {
                            timeZone: "America/New_York",
                            hour12: false,
                            weekday: "short",
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }
                        );
                        setQuery({
                          ...query,
                          from_date: fromDateConverted,
                        });
                        return;
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={4} computer={4}>
                  <label>Até</label>
                  <div>
                    <DateInput
                      name="to_date_filter"
                      iconPosition="left"
                      value={toDateFilter}
                      minDate={fromDateFilter}
                      clearable
                      fluid
                      onChange={(e, { value }) => {
                        const date = maskDate(value);

                        const toDate =
                          date !== ""
                            ? moment(date.split("/").reverse().join("-"))
                                .endOf("day")
                                .toDate()
                            : "";
                        setToDateFilter(date);

                        const toDateConverted = toDate.toLocaleString("en-US", {
                          timeZone: "America/New_York",
                          hour12: false,
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        });
                        setQuery({
                          ...query,
                          to_date: toDateConverted,
                        });
                      }}
                    />
                  </div>
                </Grid.Column>
              </>
            ) : (
              <Grid.Column mobile={8} tablet={5} computer={5}>
                <label>Data</label>
                <div>
                  <DateInput
                    name="one_date_filter"
                    iconPosition="left"
                    value={oneDateFilter}
                    clearable
                    fluid
                    onChange={(e, { value }) => {
                      const date = maskDate(value);

                      const day =
                        date !== ""
                          ? moment(date.split("/").reverse().join("-"))
                              .startOf("day")
                              .toDate()
                          : "";

                      setOneDateFilter(date);
                      const dayConverted = day.toLocaleString("en-US", {
                        timeZone: "America/New_York",
                        hour12: false,
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      });
                      setQuery({
                        ...query,
                        date_down: dayConverted,
                      });
                      return;
                    }}
                  />
                </div>
              </Grid.Column>
            )}
            <Grid.Column
              mobile={8}
              tablet={paymentTypeOption !== "boleto" ? 4 : 5}
              computer={paymentTypeOption !== "boleto" ? 4 : 5}
            >
              <>
                <label>Formas de Pagamento</label>
                <div>
                  <Dropdown
                    selection
                    search
                    fluid
                    clearable
                    onChange={(e, { value }) => {
                      setQuery({
                        ...query,
                        payment_type: value,
                      });

                      if (value === "boleto") setPaymentTypeOption(value);
                    }}
                    options={paymentTypeOptions}
                  />
                </div>
              </>
            </Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={paymentTypeOption !== "boleto" ? 4 : 6}
              computer={paymentTypeOption !== "boleto" ? 4 : 6}
            >
              <>
                <label>Status</label>
                <div>
                  <Dropdown
                    selection
                    search
                    fluid
                    clearable
                    onChange={(e, { value }) => {
                      if (value !== "") {
                        setQuery({
                          ...query,
                          status: value,
                        });
                      }
                    }}
                    options={statusOptions}
                  />
                </div>
              </>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Table celled striped role="grid" aria-labelledby="header">
        <Table.Header
          style={{
            display: "table",
            width: "100%",
            tableLayout: "fixed",
            paddingRight: "10px",
          }}
        >
          <Table.Row>
            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              ID da Transação
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Forma de Pagamento
            </Table.HeaderCell>
            {paymentTypeOption === "boleto" && (
              <Table.HeaderCell textAlign="center">
                Data da Baixa
              </Table.HeaderCell>
            )}
            <Table.HeaderCell textAlign="center">Valor Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Valor Líquido
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          className="tableList"
          onScroll={(event) => {
            const bottom =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottom) {
              (async () => {
                try {
                  dispatch.loading.isLoading(true);
                  const { client_id } = JSON.parse(
                    Storage.get("tenant_credentials")
                  );
                  const updatedStatement = await listStatements(
                    client_id,
                    statements.length,
                    query
                  );

                  if (updatedStatement.length > 0) {
                    setStatements([...statements, ...updatedStatement]);
                    return;
                  }
                } catch (e) {
                  console.log(e);
                } finally {
                  dispatch.loading.isLoading(false);
                }
              })();
            }
          }}
        >
          {statements.map(
            (
              {
                status,
                type,
                amount,
                net_value,
                card_brand,
                created_at,
                updated_at,
                id,
                installment_plan,
              },
              index
            ) => {
              const parsed_date = moment(created_at).tz("America/Sao_Paulo");
              const billet_date =
                paymentTypeOption === "boleto" &&
                moment(getLastBusinessDay(updated_at)).tz("America/Sao_Paulo");

              const idArr = id.split("");
              const rowLength = idArr.length / 2;

              const firstRow = idArr.slice(0, rowLength).join("");
              const secondRow = idArr.slice(rowLength).join("");

              return (
                <Table.Row
                  key={index}
                  style={{
                    display: "table",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <Table.Cell textAlign="center">
                    {parsed_date &&
                      `${parsed_date.format(
                        "DD/MM/YYYY"
                      )} às ${parsed_date.format("HH:mm:ss")}`}
                  </Table.Cell>
                  <Table.Cell
                    className={statusColors[status]}
                    textAlign="center"
                  >
                    {statusType[status]}
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    {firstRow} {secondRow}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {card_brand !== "" && (
                        <img
                          src={cardOptions[card_brand]}
                          alt={card_brand}
                          className="table-logo"
                        />
                      )}
                      <br />
                      {typeOptions[type]}
                      {installment_plan
                        ? ` ${installment_plan.number_installments}x`
                        : null}
                    </div>
                  </Table.Cell>
                  {paymentTypeOption === "boleto" && (
                    <Table.Cell textAlign="center">
                      {status === "succeeded" && billet_date
                        ? `${billet_date.format("DD/MM/YYYY")}`
                        : "Não informado"}
                    </Table.Cell>
                  )}
                  <Table.Cell textAlign="center">R$ {amount}</Table.Cell>
                  <Table.Cell textAlign="center">
                    R$ {handleFormatAmout(net_value)}
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Button
                floated="right"
                icon
                labelPosition="left"
                color="green"
                size="small"
              >
                <Icon name="calculator" />
                <span style={{ fontWeight: "bold" }}>Líquido:</span> R$
                {statements.length > 0 && formatedTotal("succeeded", true)}
              </Button>
              <Button
                floated="right"
                icon
                labelPosition="left"
                color="blue"
                size="small"
              >
                <Icon name="balance scale" />
                <span style={{ fontWeight: "bold" }}>Aprovado:</span> R$
                {statements.length > 0 && formatedTotal("succeeded")}
              </Button>
              <Button
                floated="right"
                icon
                labelPosition="left"
                color="red"
                size="small"
              >
                <Icon name="cancel" />
                <span style={{ fontWeight: "bold" }}>Cancelado:</span> R$
                {statements.length > 0 && formatedTotal("canceled")}
              </Button>
              <Button
                floated="right"
                icon
                labelPosition="left"
                color="yellow"
                size="small"
              >
                <Icon name="refresh" />
                <span style={{ fontWeight: "bold" }}>Pendente:</span> R$
                {statements.length > 0 && formatedTotal("pending")}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default connect()(Statement);
