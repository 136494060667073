import React from "react";
import { Grid } from "semantic-ui-react";

const Header = ({ title, children }) => (
  <Grid columns="equal">
    <Grid.Column style={{ display: "flex", alignItems: "center" }}>
      <h2 style={{ marginLeft: "1%" }}>{title}</h2>
    </Grid.Column>
    <Grid.Column>
      <Grid.Row>
        <Grid.Column>{children}</Grid.Column>
      </Grid.Row>
    </Grid.Column>
  </Grid>
);

export default Header;
