import React, { useState } from 'react';

import { Menu as SemanticMenu, Responsive, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getPersistor } from '@rematch/persist'
import IconButton from '../components/IconButton';
import * as Storage from '../services/storage';
import styled from 'styled-components';
import ModalChangePassword from '../pages/login/ModalChangePassword';

const Menu = styled(SemanticMenu)`
  box-shadow: none !important;
  margin: 0 !important;
  background-color: #f7f8fb !important;
  border: none !important;
`;

const SettingMenuItem = styled(Menu.Item)`
  right: ${props => props.right}px !important;
  position: absolute !important;

  .dropdown .disabled .text {
    display: block;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown .disabled strong {
    cursor: pointer;
  }
`;

const NavbarContainer = styled.div`
  max-width: ${props => props.windowWidth - 260}px;
`;

const Navbar = (props) => {
  const { dispatch } = props;

  const [contentContainerWidth, setContentContainerWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);

  const triggerSetting = (<IconButton name="setting"></IconButton>);
  const optionsSetting = [
    { key: 'user', text: (<span>Conectado com <strong title={Storage.get('description')}>{Storage.get('description')}</strong></span>), disabled: true },
    { key: 'change-password', icon: 'lock', text: 'Alterar Senha', value: 'change-password', onClick: () => changePasswordHandleClick() },
    { key: 'logout', icon: 'shutdown', text: 'Logout', value: 'logout', onClick: () => logoutHandleClick() }
  ];

  const handleOnUpdate = (e, { width }) => {
    setContentContainerWidth(width);
  };

  const logoutHandleClick = () => {
    const persistor = getPersistor();

    dispatch({ type: 'RESET' });

    persistor.purge();
    persistor.flush();

    Storage.clear();

    props.history.push('/');
  };

  const sidebarHandleClick = () => {
    dispatch.sidebarIsVisible.toggleSidebar();
  };

  const changePasswordHandleClick = () => {
    setOpenModal(true);
  }

  const closeModal = () => setOpenModal(false);

  return (
    <>
      <Responsive fireOnMount onUpdate={handleOnUpdate} minWidth={Responsive.onlyComputer.minWidth}>
        <NavbarContainer windowWidth={contentContainerWidth}>
          <Menu fixed="top" floated="right">
            <Menu.Item header>{Storage.get('description')}</Menu.Item>
            <SettingMenuItem name='setting' right={270}>
              <Dropdown
                trigger={triggerSetting}
                options={optionsSetting}
                icon={null}
                direction='left'
              />
            </SettingMenuItem>
          </Menu>
        </NavbarContainer>
      </Responsive>
      <Responsive fireOnMount maxWidth={Responsive.onlyComputer.minWidth}>
        <Menu fixed="top" floated="right">
          <Menu.Item>
            <IconButton name="sidebar" onClick={sidebarHandleClick} />
          </Menu.Item>
          <Menu.Item header>{Storage.get('description')}</Menu.Item>
          <SettingMenuItem name='setting' right={0}>
            <Dropdown
              trigger={triggerSetting}
              options={optionsSetting}
              icon={null}
              direction='left'
            />
          </SettingMenuItem>
        </Menu>
      </Responsive>
      <ModalChangePassword
        open={openModal}
        closeModal={closeModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarIsVisible: state.sidebarIsVisible
});

export default connect(mapStateToProps)(Navbar);

