import { store as notificationStore } from 'react-notifications-component';

const animationDefaultTime = 2500;

export const dangerNotification = (title, message) => {
  notificationStore.addNotification({
    title: title,
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: animationDefaultTime
    }
  });
};

export const successNotification = (title, message) => {
  notificationStore.addNotification({
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: animationDefaultTime
    }
  });
};
