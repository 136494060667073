import React, { useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import SalesList from './SalesList';
import PayerForm from './PayerForm';
import BilletForm from './BilletForm';
import PageNotFound from '../../components/PageNotFound';

import { Conclusion } from './Conclusion';

import { Step, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  isBilletsAbleToPayerForm,
  isBilletsAbleToReceiptForm,
} from '../../services/sales';

import styled from 'styled-components';

const ContentContainer = styled.div`
  max-width: ${(props) => props.windowWidth}px;
`;

const Steps = (props) => {
  const { pathname } = props.location;
  const { simulator, payer, sale, billets } = props;

  const [contentContainerWidth, setContentContainerWidth] = useState(window.innerWidth);

  const handleOnUpdate = (e, { width }) => setContentContainerWidth(width);

  const handleDebtsStepEnableBehavior = () => (true ? { disabled: false } : { disabled: true });

  const handlePayerStepEnableBehavior = () =>
    isBilletsAbleToPayerForm(billets, simulator) ? { disabled: false } : { disabled: true };

  const handleReceiptStepEnableBehavior = () =>
    isBilletsAbleToReceiptForm(billets, simulator, payer, sale) ? { disabled: false } : { disabled: true };

  const handleStepBehavior = (pathname, route, handleClick) =>
    pathname === route ? { active: true } : { onClick: handleClick };

  const Steps = () => (
    <>
      <Step
        {...handleDebtsStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/billets/validate', () => props.history.push('/admin/billets/validate'))}
      >
        <Step.Content>
          <Step.Title>Débitos</Step.Title>
          <Step.Description>Informe os débitos</Step.Description>
        </Step.Content>
      </Step>
      <Step
        {...handlePayerStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/billets/payer', () => props.history.push('/admin/billets/payer'))}
      >
        <Step.Content>
          <Step.Title>Pagador</Step.Title>
          <Step.Description>Informe os dados do pagador</Step.Description>
        </Step.Content>
      </Step>
      <Step
        {...handleReceiptStepEnableBehavior()}
        {...handleStepBehavior(pathname, '/admin/billets/receipt', () => props.history.push('/admin/billets/receipt'))}
      >
        <Step.Content>
          <Step.Title>Conclusão</Step.Title>
          <Step.Description>Concluir venda</Step.Description>
        </Step.Content>
      </Step>
    </>
  );

  return (
    <>
      <Responsive fireOnMount onUpdate={handleOnUpdate} minWidth={Responsive.onlyComputer.minWidth}>
        <ContentContainer windowWidth={contentContainerWidth}>
          <Responsive as={Step.Group} fluid size='mini'>
            <Steps />
          </Responsive>
        </ContentContainer>
      </Responsive>
      <Responsive fireOnMount maxWidth={Responsive.onlyComputer.minWidth}>
        <Responsive as={Step.Group} vertical fluid size='mini'>
          <Steps />
        </Responsive>
      </Responsive>
    </>
  );
};

const BilletsSalesForm = (props) => {
  const { pathname } = props.location;
  const { simulator, payer, sale, billets } = props;

  let isAbleToForm = true;
  let pushTo = null;

  const validPages = ['validate', 'payer', 'checkout', 'receipt'];
  const pageIsValid = (page) => validPages.filter((validPage) => validPage === page).length > 0;

  return (
    <>
      {pathname !== '/admin/billets' && pageIsValid(pathname.split('/').pop()) && <Steps {...props} />}
      <Route
        component={({ match }) => {
          if (pathname === `${match.url}/payer` && !isBilletsAbleToPayerForm(billets, simulator)) {
            pushTo = `${match.url}/validate`;
          }

          if (pathname === `${match.url}/receipt` && !isBilletsAbleToReceiptForm(billets, simulator, payer, sale)) {
            pushTo = `${match.url}/payer`;
          }

          if (null !== pushTo) {
            isAbleToForm = false;
            props.history.push(pushTo);
          }

          return (
            <>
              {isAbleToForm && (
                <>
                  <Switch>
                    <Route exact path={`${match.url}`} component={SalesList} />
                    <Route path={`${match.url}/validate`} component={BilletForm} />
                    <Route path={`${match.url}/payer`} component={PayerForm} />
                    <Route path={`${match.url}/receipt`} component={Conclusion} />
                    <Route component={PageNotFound} />
                  </Switch>
                </>
              )}
            </>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  simulator: state.simulator,
  payer: state.payer,
  sale: state.sale,
  billets: state.billets,
});

export default connect(mapStateToProps)(BilletsSalesForm);
