import VMasker from 'vanilla-masker';

export const CEP_FORMAT = /^\d{5}[-|\s]?\d{3}$/;
export const CPF_FORMAT = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
export const CNPJ_FORMAT = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
export const PHONE_FORMAT = /^\(\d{2}\)[\s]\d{4,5}-\d{4}$/;

export const maskNumber = (value) => value.replace(/\D/g, '');

export const unmaskNumber = (value) => value.replace(/[^\d]/g, '');

export const formatAmount = (amount) => VMasker.toMoney(amount);

export const maskCardNumber = (card_number) => 'X'.repeat(12).concat(' ').concat(card_number.slice(12));

export const maskCreditCardNumber = (creditCardNumber) => VMasker.toPattern(creditCardNumber, '9999 9999 9999 9999');

export const maskIncludeLastDigitsInCardNumber = (card_number) =>
  'X'.repeat(12).concat(' ').concat(card_number.slice(12));

export const unmaskCreditCardNumber = (creditCardNumber) => creditCardNumber.replace(/\s/g, '');

export const maskDate = (date) => VMasker.toPattern(date, 'SS/SS/SSSS');

export const maskDateShort = (date) => VMasker.toPattern(unmaskNumber(date), 'SS/SS');

export const maskMoney = (value, float = false) => {
  const toParse = !float ? value.replace(/\./g, '').replace(/,/, '.') : value;

  return parseFloat(toParse, 10)
    .toFixed(2)
    .toString()
    .replace(/\./, ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const unmaskMoney = (value) => parseFloat(value.replace(/\./g, '').replace(/,/, '.'), 10);

export const maskCpf = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');

export const maskCnpj = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{4})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2');

export const maskCpfOrCnpj = (value) => {
  if (!value) {
    return value;
  }

  if (unmaskCpfOrCnpj(value).length <= 11) {
    return maskCpf(value);
  } else {
    return maskCnpj(value);
  }
};

export const unmaskCpfOrCnpj = (value) => value.replace(/\D/g, '');

export const maskPhoneNumber = (value) =>
  value
    ?.replace(/\D/g, '')
    ?.replace(/(\d{2})(\d)/, '($1) $2')
    ?.replace(/(\d{4,5})(\d{4})/, '$1-$2') ?? '';

export const unmaskPhoneNumber = (value) => value.replace(/\D/g, '');

export const maskBilletNumber = (billetNumber) =>
  VMasker.toPattern(VMasker.toNumber(billetNumber), 'SSSSS.SSSSS SSSSS.SSSSS SSSSS.SSSSS SSSSS.SSSSS SSSSSSSS');

export const maskBankBilletNumber = (billetNumber) =>
  VMasker.toPattern(VMasker.toNumber(billetNumber), 'SSSSS.SSSSS SSSSS.SSSSSS SSSSS.SSSSSS S SSSSSSSSSSSSSS');

export const maskTributeBilletNumber = (billetNumber) =>
  VMasker.toPattern(VMasker.toNumber(billetNumber), 'SSSSSSSSSSS S SSSSSSSSSSS S SSSSSSSSSSS S SSSSSSSSSSS S');

export const unmaskBilletNumber = (billetNumber) => billetNumber.replace(/[^\d]/g, '');
