import React from 'react';

import { Loader as SemanticLoader, Dimmer } from 'semantic-ui-react';

const Loader = () => (
  <>
    <Dimmer active inverted>
      <SemanticLoader size='big' />
    </Dimmer>
  </>
);

export default Loader;
