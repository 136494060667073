import * as Api from "./api";

export const list = async (sellerId, from_date, to_date, offset, isCredit) => {
  const headers = {
    token: process.env.REACT_APP_API_PARCELA_TOKEN,
    Authorization: process.env.REACT_APP_API_PARCELA_KEY,
  };

  const response = await Api.doRequestPayParcela(
    `/sellers/${sellerId}/receivables?sort=time-descending&limit=1000&offset=${offset}${
      from_date ? "&" + from_date : ""
    }${to_date ? "&" + to_date : ""}${isCredit ? "&status=pending" : ""}`,
    "GET",
    headers
  );

  return response.data;
};
