import React, { useState } from 'react';
import { Modal, Button, Input, Form } from 'semantic-ui-react';
import { dangerNotification, successNotification } from '../../services/notification';
import { changePassword } from '../../services/api/tenants';
import styled from 'styled-components';

const ButtonStyled = styled(Button)`
  margin: 0 5px 10px 0 !important;
`;

const ModalChangePassword = (props) => {
  const open = props.open || false;
  const [isOldPassword, setIsOldPassword] = useState(true);
  const [isNewPassword, setIsNewPassword] = useState(true);
  const [isCfmPassword, setIsCfmPassword] = useState(true);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cfmPassword, setCfmPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!oldPassword) {
      dangerNotification('Atenção', 'Favor informar a senha atual!');
      return;
    }

    if (!newPassword) {
      dangerNotification('Atenção', 'Favor informar a nova senha!');
      return;
    }

    if (!cfmPassword) {
      dangerNotification('Atenção', 'Favor confirmar a nova senha!');
      return;
    }

    if (newPassword !== cfmPassword) {
      dangerNotification('Atenção', 'Nova senha e sua confirmação não coincidem!');
      return;
    }

    try {
      const values = {};
      const tenantId = localStorage.getItem('uid');
      const token = localStorage.getItem('token');

      values.old_password = oldPassword;
      values.new_password = newPassword;
      values.cfm_password = cfmPassword;
      values.token = token;

      changePassword(tenantId, values, process.env.REACT_APP_DEFAULT_OWNER);
      successNotification('Success', 'Senha alterada com sucesso, você será redirecionado para tela de login!');
      props.closeModal();
    }
    catch (e) {
      dangerNotification('Atenção', 'Ocorreu um erro na alteração de senha!');
    }
  }

  const handleOnMount = (e) => {
    setOldPassword('');
    setNewPassword('');
    setCfmPassword('');
  }

  return (
    <Modal
      size='mini'
      open={open}
      onClose={(e) => { props.closeModal() }}
      as={Form} onSubmit={(e) => handleSubmit(e)}
      onMount={(e) => handleOnMount(e)} >
      <Modal.Header>Redefina sua senha</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Input
            fluid
            placeholder='Senha Atual'
            type={isOldPassword ? 'password' : 'text'}
            icon={{ name: 'eye', link: true, onClick: (e) => setIsOldPassword(!isOldPassword) }}
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
          />
          <br />
          <Input
            fluid
            placeholder='Nova Senha'
            type={isNewPassword ? 'password' : 'text'}
            icon={{ name: 'eye', link: true, onClick: (e) => setIsNewPassword(!isNewPassword) }}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
          <br />
          <Input
            fluid
            placeholder='Confirmação de Senha'
            type={isCfmPassword ? 'password' : 'text'}
            icon={{ name: 'eye', link: true, onClick: (e) => setIsCfmPassword(!isCfmPassword) }}
            onChange={(e) => setCfmPassword(e.target.value)}
            value={cfmPassword}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ButtonStyled floated="right" primary>Salvar</ButtonStyled>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalChangePassword;
