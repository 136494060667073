import { validate as emailValidator } from 'email-validator';
import { cpf, cnpj } from 'cpf-cnpj-validator';

/**
 * Validate not empty entry
 * @param {*} value
 */
export function notEmpty(value) {
  if (value === undefined || value === null || value === '') {
    return 'Campo não pode ser vazio';
  }
}

/**
 * Validate email
 * @param {*} email
 */
export function validateEmail(email) {
  if (!emailValidator(email)) {
    return 'E-Mail não é valido!';
  }
}

/**
 * Check if document is CPF or CNPJ
 * @param {*} document
 */
export function validateDocument(document) {
  if (!(cpf.isValid(document) || cnpj.isValid(document))) {
    return 'Documento invalido';
  }
}

/**
 * Validate if date is MM/YY
 * @param {*} date
 */
export function validateExpiryDate(date) {
  const fields = date.split('/');
  const month = parseInt(fields[0]);
  const year = parseInt(fields[1]);
  if (!fields || fields.length < 2 || isNaN(month) || isNaN(year) || month <= 0 || month > 12) {
    return 'Data invalida';
  }

  const today = new Date();
  if (year < today.getFullYear() % 100 || (year === today.getFullYear() % 100 && month < today.getMonth() + 1)) {
    return 'Cartão vencido';
  }
}
