import React from "react";
import { connect } from "react-redux";

import { Formik, Field, ErrorMessage } from "formik";
import { Grid, Header, Divider, Responsive } from "semantic-ui-react";
import styled from "styled-components";

import { SendButton } from "../../components/SendButton";
import Input from "../../components/Input";
import Card from "../../containers/Card";
import AddressForm from "../../components/AddressForm";

import { maskCpfOrCnpj, unmaskCpfOrCnpj } from "../../services/masks";
import { save as saveSale } from "../../services/api/sales";
import { round } from "../../services/math";
import * as Storage from "../../services/storage";
import * as validators from "../../services/validators";

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
  font-size: 10px;
`;

const PersonalInfoFields = (props) => {
  const { setFieldValue, touched, errors } = props;

  return (
    <>
      <Grid.Row>
        <Grid.Column width={8}>
          <label>Nome</label>
          <label style={{ color: "red" }}>*</label>
          <div>
            <Field
              name="name"
              type="text"
              component={Input}
              fluid
              validate={validators.notEmpty}
              error={touched?.name && errors?.name !== undefined}
            />
            <Error name="name" component="div" />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>CPF/CNPJ</label>
          <label style={{ color: "red" }}>*</label>
          <div>
            <Field
              name="document"
              type="text"
              maxLength={18}
              onChange={(e) => {
                setFieldValue("document", maskCpfOrCnpj(e.target.value));
              }}
              component={Input}
              fluid
              validate={validators.validateDocument}
              error={touched?.document && errors?.document !== undefined}
            />
            <Error name="document" component="div" />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>RG</label>
          <div>
            <Field
              name="national_id_card"
              type="text"
              component={Input}
              fluid
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          <label>E-Mail</label>
          <label style={{ color: "red" }}>*</label>
          <div>
            <Field
              name="email"
              type="email"
              component={Input}
              fluid
              validate={validators.validateEmail}
              error={touched?.email && errors?.email !== undefined}
            />
            <Error name="email" component="div" />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <label>Telefone</label>
          <div>
            <Field name="phone" type="phone" component={Input} fluid />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">Endereço</Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const PayerForm = (props) => {
  const { dispatch, simulator, billets } = props;
  let { payer } = props;

  if (!Boolean(Object.keys(payer)[0])) {
    payer = {
      name: "",
      document: "",
      national_id_card: "",
      phone: "",
      email: "",
      user_id: "",
      address: {
        postal_code: "",
        street: "",
        district: "",
        number: "",
        complement: "",
        state: "",
        city: "",
      },
    };
  }

  const buildSale = (payer) => {
    const total_with_interest = parseFloat(simulator.total_with_interest);
    const original_amount = simulator.original_amount;
    const parcela_amount = round(total_with_interest - original_amount, 2);

    const payee = {
      name: Storage.get("description"),
      address: JSON.parse(Storage.get("tenant_address")),
      phone: Storage.get("tenant_phone"),
      document: Storage.get("tenant_document"),
    };

    return {
      type: "billets",
      description: simulator.description,
      original_amount,
      payment_type: simulator.payment_type,
      installment_amount: simulator.installment_amount,
      total_with_interest: simulator.total_with_interest,
      payee: {
        ...payee,
      },
      payer: {
        address: {
          ...payer.address,
        },
        document: unmaskCpfOrCnpj(payer.document),
        email: payer.email,
        name: payer.name,
        national_id_card: payer.national_id_card,
        phone: payer.phone,
      },
      created_at: new Date().toISOString(),
      split_rules: {
        original_amount,
        total_with_interest,
        parcela_amount,
      },
      billets,
      cartorio: Storage.get("description"),
    };
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        initialValues={payer}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          const sale = buildSale(values);

          try {
            dispatch.loading.isLoading(true);

            const selectedOwner = Storage.get("selected_owner");
            const tenantId = Storage.get("uid");
            const saleResponse = await saveSale(tenantId, selectedOwner, sale);

            dispatch.payer.updatePayer(values);
            dispatch.sale.createSale({
              ...sale,
              id: saleResponse.id,
              checkout: saleResponse.checkout,
            });

            props.history.push("/admin/billets/receipt");
          } catch (e) {
            console.log(e);
          } finally {
            dispatch.loading.isLoading(false);
          }
        }}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <SendButton />
            <Responsive fireOnMount as={Card}>
              <Grid stackable>
                <PersonalInfoFields {...formProps} />
                <AddressForm {...formProps} />
              </Grid>
            </Responsive>
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  payer: state.payer,
  simulator: state.simulator,
  billets: state.billets,
});

export default connect(mapStateToProps)(PayerForm);
