import * as Api from './api';

export const sendDocument = async (document, client, owner) => {
  const formData = new FormData();

  formData.append("file", document);
  formData.append("category", 'Documento de Identificação');
  formData.append("description", 'Documento de Identificação');

  const endpoint = `/owners/${owner}/tenants/${client.id}/upload-document`;
  const headers = {
    alias: `${process.env.REACT_APP_PROJECT_ID}`,
    authorization: `Basic ${process.env.REACT_APP_PARCELA_API_AUTHORIZATION}`
  };

  try {
    return await Api.doRequestDocument(endpoint, headers, formData);
  } catch (e) {
    throw new Error('Falha ao enviar arquivo!');
  }
};
