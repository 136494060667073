import React from 'react';

import { ErrorMessage, Field } from 'formik';
import { Divider, Grid, Header } from 'semantic-ui-react';

import styled from 'styled-components';
import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';

import { banks } from '../../services/banks';
import { maskNumber } from '../../services/masks';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
`;

const BankAccountFields = (props) => {
  const { setFieldValue } = props;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            Dados Bancários
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <label>Banco</label>
          <Field name="bank_account.bank_code" type="text" component={Dropdown} options={banks} fluid />
          <Error name="bank_account.bank_code" component="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <label>Agência</label>
          <Field name="bank_account.routing_number" type="text" component={Input} fluid
            onChange={(e) => {
              setFieldValue('bank_account.routing_number', maskNumber(e.target.value));
            }}
          />
          <Error name="bank_account.routing_number" component="div" />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>Dígito</label>
          <Field name="bank_account.routing_number_digit" type="text" component={Input} fluid
            onChange={(e) => {
              setFieldValue('bank_account.routing_number_digit', maskNumber(e.target.value));
            }}
          />
          <Error name="bank_account.routing_number_digit" component="div" />
        </Grid.Column>
        <Grid.Column width={6}>
          <label>Conta Corrente</label>
          <Field name="bank_account.account_number" type="text" component={Input} fluid
            onChange={(e) => {
              setFieldValue('bank_account.account_number', maskNumber(e.target.value));
            }}
          />
          <Error name="bank_account.account_number" component="div" />
        </Grid.Column>
        <Grid.Column width={2}>
          <label>Dígito</label>
          <Field name="bank_account.account_number_digit" type="text" component={Input} fluid
            onChange={(e) => {
              setFieldValue('bank_account.account_number_digit', maskNumber(e.target.value));
            }}
          />
          <Error name="bank_account.account_number_digit" component="div" />
        </Grid.Column>

      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <label>CPF ou CNPJ do Titular</label>
          <Field name="bank_account.document" type="text" component={Input} readOnly fluid />
          <Error name="bank_account.document" component="div" />
        </Grid.Column>
        <Grid.Column width={12}>
          <label>Nome do Titular</label>
          <Field name="bank_account.holder_name" type="text" component={Input} readOnly fluid />
          <Error name="bank_account.holder_name" component="div" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default BankAccountFields;
