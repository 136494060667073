import React from 'react';

import { Grid, Responsive, Header, Message, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Card from '../../containers/Card';

const StyleParagraph = styled.p`
  margin: 0 auto;
  width: 85%;
  word-break: break-word;
  line-height: 22px;
`;

export const DocumentAnalysis = (props) =>
  <>
    <Responsive>
      <Grid centered columns={2}>
        <Grid.Row>
          <Grid.Column columns={1} width={10}>
            <Responsive as={Card}>
              <Grid.Column textAlign="center">
                <Message.Content >
                  <Header textAlign="center" as='h1'>{ props.name }</Header>
                  <br />
                  <StyleParagraph>
                    Obrigado pelo envio das informações!<br />
                    Seus dados já estão sendo analisados. Aguarde que lhe responderemos em breve, via e-mail.
                  </StyleParagraph>
                </Message.Content>
              </Grid.Column>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column columns={1} width={10}>
            <Link to="/">
              <Button type="button" color="black" fluid>Sair</Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Responsive>
  </>

export default DocumentAnalysis;
