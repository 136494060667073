import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Formik, Field, ErrorMessage } from "formik";
import {
  Grid,
  Header,
  Divider,
  Responsive,
  Segment,
  Dropdown,
} from "semantic-ui-react";
import styled from "styled-components";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import { SendButton } from "../../components/SendButton";
import Input from "../../components/Input";
import Title from "../../components/Title";
import Card from "../../containers/Card";
import Sidebar from "../../containers/Sidebar";

import {
  maskCreditCardNumber,
  unmaskCreditCardNumber,
  maskDateShort,
  maskMoney,
} from "../../services/masks";
import * as validators from "../../services/validators";
import { saleByToken, checkoutPayment } from "../../services/api/sales";
import {
  successNotification,
  dangerNotification,
} from "../../services/notification";
import "./styles.css";

const Box = styled(Segment)`
  width: 100%;
  padding: 1.5em;
`;

const ColumnContainer = styled(Grid.Column)`
  padding: 2.5em;
  margin-top: 3em;
`;

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
  font-size: 10px;
`;

const Text = styled(Header)`
  font-weight: normal;
  padding: 0.1em;
`;

const Label = styled.label`
  display: inline-block;
  padding-top: 0.8em;
`;

const CardForm = (props) => {
  const { setFieldValue, touched, errors, setCardFocus } = props;

  return (
    <>
      <Label>Número do cartão:</Label>
      <div>
        <Field
          name="number"
          type="text"
          component={Input}
          fluid
          validate={validators.notEmpty}
          error={touched?.number && errors?.number !== undefined}
          maxLength={19}
          onChange={(e) => {
            setCardFocus("number");
            setFieldValue("number", maskCreditCardNumber(e.target.value));
          }}
        />
        <Error name="number" component="div" />
      </div>
      <Label>Nome impresso no cartão:</Label>
      <div>
        <Field
          name="name"
          type="text"
          component={Input}
          fluid
          validate={validators.notEmpty}
          error={touched?.name && errors?.name !== undefined}
          onChange={(e) => {
            setCardFocus("name");
            setFieldValue("name", e.target.value.toUpperCase());
          }}
        />
        <Error name="name" component="div" />
      </div>
      <Label>Data de vencimento:</Label>
      <div>
        <Field
          name="expiry"
          type="text"
          component={Input}
          fluid
          validate={validators.validateExpiryDate}
          maxLength={5}
          error={touched?.expiry && errors?.expiry !== undefined}
          onChange={(e) => {
            setCardFocus("expiry");
            setFieldValue("expiry", maskDateShort(e.target.value));
          }}
        />
        <Error name="expiry" component="div" />
      </div>
      <Label>Código de segurança:</Label>
      <div>
        <Field
          name="cvc"
          type="text"
          component={Input}
          fluid
          validate={validators.notEmpty}
          error={touched?.cvc && errors?.cvc !== undefined}
          onChange={(e) => {
            setCardFocus("cvc");
            setFieldValue("cvc", e.target.value.replace(/[^\d]/g, ""));
          }}
        />
        <Error name="cvc" component="div" />
      </div>
    </>
  );
};

const CheckoutForm = (props) => {
  const { dispatch } = props;

  const [portrait, setPortrait] = useState(
    window.innerWidth < 600 ? true : false
  );

  const [sale, setSale] = useState({});

  const [cardFocus, setCardFocus] = useState("");

  const [success, setSuccess] = useState(false);

  const [installments, setInstallments] = useState(0);

  useEffect(() => {
    (async () => {
      dispatch.loading.isLoading(true);
      const token = props.match.params.token;
      try {
        setSale(await saleByToken(token));
      } catch (_) {}
      dispatch.loading.isLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnUpdate = (e, { width }) => {
    dispatch.sidebarIsVisible.closeSidebar();
    if (width < 600) setPortrait(true);
    else setPortrait(false);
  };

  return (
    <>
      <Responsive onUpdate={handleOnUpdate} as={Sidebar} {...props}>
        <Grid.Row>
          {!sale.checkout && (
            <ColumnContainer>
              <Title tag="h2">Checkout</Title>
            </ColumnContainer>
          )}
          {sale.checkout && sale?.checkout?.status !== "pending" && (
            <ColumnContainer>
              <Title tag="h2">Checkout</Title>
              <Card>
                <h4>Compra indisponível!</h4>
                <br></br>
                <h5>Já pode fechar essa aba!</h5>
              </Card>
            </ColumnContainer>
          )}
          {success && (
            <ColumnContainer>
              <Title tag="h2">Checkout</Title>
              <Card>
                <h4>Boleto pago com sucesso!</h4>
              </Card>
            </ColumnContainer>
          )}
          {sale?.checkout?.status === "pending" && !success && (
            <ColumnContainer>
              <Formik
                initialValues={{ number: "", name: "", expiry: "", cvc: "" }}
                fluid
                validateOnChange={false}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  if (
                    !sale.simulation.find((e) => e.installment === installments)
                  ) {
                    dangerNotification(
                      "Erro",
                      "Selecione a quantidade de parcelas!"
                    );
                    return;
                  }

                  const request = {
                    token: props.match.params.token,
                    installments: installments,
                    card: {
                      number: unmaskCreditCardNumber(values?.number),
                      holder_name: values?.name,
                      expiration_month: values?.expiry?.split("/")[0],
                      expiration_year:
                        new Date().getFullYear().toString().substring(0, 2) +
                        values?.expiry?.split("/")[1],
                      security_code: values?.cvc,
                    },
                  };

                  dispatch.loading.isLoading(true);
                  try {
                    await checkoutPayment(request);
                    successNotification(
                      "Sucesso!",
                      "Compra efetuada com sucesso!"
                    );
                    setSuccess(true);
                  } catch (e) {
                    if (e?.response?.data?.message === "invalid signature") {
                      dangerNotification("Erro", "Compra invalidada!");
                    } else {
                      dangerNotification(
                        "Erro",
                        "Não foi possível concluir a compra!"
                      );
                    }
                  } finally {
                    dispatch.loading.isLoading(false);
                  }
                }}
              >
                {(formProps) => (
                  <form onSubmit={formProps.handleSubmit}>
                    <Title tag="h2">Checkout</Title>
                    <Responsive fireOnMount as={Card}>
                      <Header as="h3">Informações da compra</Header>
                      <Divider clearing />
                      <Grid columns={2} divided>
                        <Grid.Column
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid.Row>
                            <Text as="h4">Código de barra: </Text>
                            <div>
                              <Text as="small">
                                <strong>{sale?.barcode}</strong>
                              </Text>
                            </div>
                          </Grid.Row>
                          <Grid.Row>
                            <Text as="h4">Valor: </Text>
                            <div>
                              <Text as="h4">
                                <strong>{`R$ ${maskMoney(
                                  sale?.original_amount?.toString() ?? "0",
                                  true
                                )}`}</strong>
                              </Text>
                            </div>
                          </Grid.Row>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid.Row>
                            <Text as="h4">Modo de Pagamento: </Text>
                            <div>
                              <Dropdown
                                selection
                                search
                                fluid
                                className="activeField"
                                // value={selectedFilter.value}
                                onChange={(e, data) => {
                                  setInstallments(data.value);
                                }}
                                options={sale.simulation
                                  .sort((a, b) =>
                                    a.installment > b.installment ? -1 : 1
                                  )
                                  .map((installments) => {
                                    const { installment } = installments;
                                    return {
                                      key: sale.simulation.indexOf(
                                        installments
                                      ),
                                      value: installment,
                                      text: (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span>
                                            {installment}x de R${" "}
                                            {maskMoney(
                                              (installments?.installment_amount_cents ??
                                                0) / 100,
                                              true
                                            )}
                                          </span>
                                          <span>
                                            Total: R${" "}
                                            {maskMoney(
                                              (installments?.total_amount_cents ??
                                                0) / 100,
                                              true
                                            )}
                                          </span>
                                        </div>
                                      ),
                                    };
                                  })}
                              />
                            </div>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    <Responsive fireOnMount as={Card}>
                      <Header as="h3">Cartão de crédito</Header>
                      <Divider clearing />
                      <Grid columns={portrait ? 1 : 2} divided stackable>
                        <Grid.Column>
                          <CardForm
                            {...formProps}
                            setCardFocus={setCardFocus}
                          />
                          {portrait && (
                            <Box>
                              <Cards
                                number={formProps?.values?.number ?? ""}
                                name={formProps?.values?.name ?? ""}
                                expiry={formProps?.values?.expiry ?? ""}
                                cvc={formProps?.values?.cvc ?? ""}
                                focused={cardFocus}
                              />
                            </Box>
                          )}
                          <SendButton label={"Enviar"} position={"left"} />
                        </Grid.Column>
                        {!portrait && (
                          <Grid.Column>
                            <Box>
                              <Cards
                                number={formProps?.values?.number ?? ""}
                                name={formProps?.values?.name ?? ""}
                                expiry={formProps?.values?.expiry ?? ""}
                                cvc={formProps?.values?.cvc ?? ""}
                                focused={cardFocus}
                              />
                            </Box>
                          </Grid.Column>
                        )}
                      </Grid>
                    </Responsive>
                  </form>
                )}
              </Formik>
            </ColumnContainer>
          )}
        </Grid.Row>
      </Responsive>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarIsVisible: state.sidebarIsVisible,
  token: state.token,
});

export default connect(mapStateToProps)(CheckoutForm);
