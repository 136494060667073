import React, { useState, useEffect } from 'react';

import { Formik, Field } from 'formik';
import { connect } from 'react-redux';

import { calculateAmount, calculateInstallmentAmount } from '../../services/calculator';

import { SendButton } from '../../components/SendButton';
import { getPaymentTypes } from '../../services/api/paymentTypes';

import * as Storage from '../../services/storage';

import { Grid, Responsive } from 'semantic-ui-react';
import { formatAmount, unmaskMoney, maskMoney } from '../../services/masks';

import Card from '../../containers/Card';
import Input from '../../components/Input';
import ErrorMessage from '../../components/ErrorMessage';
import Simulator from '../../components/Simulator';
import SimulatorStatistics from '../../components/SimulatorStatistics';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required('A descrição é obrigatória'),
  original_amount: Yup.string()
    .required('Valor não informado!')
});

const SimulatorForm = (props) => {
  const [simulator, setSimulator] = useState(Boolean(Object.keys(props.simulator)[0]) ? props.simulator : {
    description: '',
    original_amount: '',
    payment_type: {},
    total_with_interest: 0,
    installment_amount: 0
  });

  const { dispatch } = props;

  const [original_amount, setOriginalAmount] = useState('');
  const [payment_types, setPaymentTypes] = useState([]);

  useEffect(() => {
    if (simulator.original_amount && simulator.original_amount !== '') {
      updateSimulator(simulator.description, simulator.original_amount, simulator.payment_type);
      setOriginalAmount(simulator.original_amount);
    }

    (async() => {
      dispatch.loading.isLoading(true);

      const tenantId = Storage.get('uid');
      const selectedOwner = Storage.get('selected_owner');
      const getPaymentTypesResponse = await getPaymentTypes(tenantId, selectedOwner);

      setPaymentTypes(getPaymentTypesResponse);

      dispatch.loading.isLoading(false);
    })();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSimulator = (description, original_amount, payment_type) => {
    let total_with_interest = calculateAmount(
      original_amount,
      payment_type.customer_onlending_fee,
      payment_type
    );

    let installment_amount = calculateInstallmentAmount(
      original_amount,
      payment_type.customer_onlending_fee,
      payment_type
    );

    setSimulator({
      description,
      original_amount: maskMoney(original_amount, true),
      payment_type,
      total_with_interest: (isNaN(total_with_interest) ? 0 : total_with_interest),
      installment_amount: (isNaN(installment_amount) ? 0 : installment_amount),
    });
  };

  const handlePaymentTypeChange = (selectedPaymentType) => {
    updateSimulator(simulator.description, unmaskMoney(simulator.original_amount), selectedPaymentType);
  };

  return (
    <>
      <Formik initialValues={simulator}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (! Boolean(Object.keys(simulator.payment_type)[0])) {
            setErrors({ paymentType: 'Opção não selecionada!' });

            return false;
          }
          const simulatorData = {
            description: values.description,
            original_amount,
            payment_type: simulator.payment_type,
            total_with_interest: (
              simulator.total_with_interest = calculateAmount(
                original_amount,
                simulator.payment_type.customer_onlending_fee,
                simulator.payment_type
              )
            ),
            installment_amount: (
              simulator.installment_amount = calculateInstallmentAmount(
                original_amount,
                simulator.payment_type.customer_onlending_fee,
                simulator.payment_type
              )
            )
          };

          dispatch.simulator.updateSimulator(simulatorData);

          return props.history.push('/admin/sales/payer');
        }}
        render={({ errors, setFieldValue, values, setFieldTouched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <SendButton />
            <Responsive as={Card}>
              <Grid stackable>
                <>
                  <SimulatorStatistics original_amount={original_amount}
                    installments={simulator.payment_type.installments}
                    installment_amount={simulator.installment_amount}
                    total_with_interest={simulator.total_with_interest} />

                  <Grid.Row>
                    <Grid.Column width={12}>
                      <label>Descrição</label>
                      <div>
                        <Field name="description"
                          type="text"
                          component={Input}
                          required
                          onChange={(e) => {
                            setFieldValue('description', e.target.value);
                          }}
                          fluid />
                          { errors.description && (<ErrorMessage>{errors.description}</ErrorMessage>) }
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <label>Valor da venda</label>
                      <div>
                        <Field name="original_amount"
                          type="text"
                          onChange={(e) => {
                            const value = formatAmount(e.target.value);
                            const unmaskedValue = unmaskMoney(value);

                            setOriginalAmount(unmaskedValue);
                            setFieldValue('original_amount', value);

                            updateSimulator(values.description, unmaskedValue, simulator.payment_type);
                          }}
                          component={Input}
                          required
                          fluid />
                          { errors.original_amount && (<ErrorMessage>{errors.original_amount}</ErrorMessage>) }
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  { original_amount >= 1 && (
                    <>
                      <Simulator payment_types={payment_types}
                        simulator={simulator}
                        original_amount={original_amount}
                        typeSelectionHandler={handlePaymentTypeChange}
                        totalWithInterest={simulator.total_with_interest}
                        installmentAmount={simulator.installment_amount} />
                    </>
                  )}
                </>
              </Grid>
            </Responsive>
          </form>
        )} />
    </>
  );
};

const mapStateToProps = state => ({
  simulator: state.simulator
});

export default connect(mapStateToProps)(SimulatorForm);
