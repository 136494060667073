import * as Api from "./api";

export const list = async (sellerId, offset, query) => {
  const headers = {
    token: process.env.REACT_APP_API_PARCELA_TOKEN,
    Authorization: process.env.REACT_APP_API_PARCELA_KEY,
  };

  const payment_type =
    query.payment_type !== "" && "&payment_type=" + query.payment_type;
  const status = query.status !== "" && "&status=" + query.status;
  const from_date =
    query.from_date !== "" && "&date_range%5Bgte%5D=" + query.from_date;
  const to_date =
    query.to_date !== "" && "&date_range%5Blte%5D=" + query.to_date;
  const date_down = query.date_down !== "" && "&date_down=" + query.date_down;

  const response = await Api.doRequestPayParcela(
    `/sellers/${sellerId}/extract?sort=time-descending&offset=${offset}${
      payment_type ? payment_type : ""
    }${status ? status : ""}${from_date ? from_date : ""}${
      to_date ? to_date : ""
    }${date_down ? date_down : ""}`,
    "GET",
    headers
  );

  return response.data;
};
