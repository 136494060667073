import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import Pdf from '@mikecousins/react-pdf';
import styled from 'styled-components';

const LinkModal = styled.a`
  cursor: pointer;
  &:active {
    color: #1e70bf;
  }
  &:visited {
    color:#1e70bf;
  }
  &:hover {
    color: #1e70bf;
  }
`;

const Lists = styled.ul`
  display: flex;
  list-style: none;
`;

const styleModal = { "text-align": "center" };

const DivLoading = styled.div`"text-align": "center;     position: relative;"`;
const SpanCenter = styled.div`"text-align": "center"`;

const ModalTermsOfService = (props) => {

  const {
    textBegin,
    textLink,
    textEnd,
    termsOfService
  } = props;
  const [page, setPage] = useState(1);

  return (
    <>
      {textBegin}
      <Modal trigger={<LinkModal>{textLink}</LinkModal>} style={styleModal}>
        <Pdf file={termsOfService} page={page}>
          {({ pdfDocument, pdfPage, canvas }) => (
            <>
              {!pdfDocument && <DivLoading><SpanCenter align="center">Aguarde...</SpanCenter></DivLoading>}
              {canvas}
              {Boolean(pdfDocument && pdfDocument.numPages) && (
                <nav>
                  <Lists className="pager">
                    <li className="previous">
                      <Button
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        Previous
                  </Button>
                    </li>
                    <li className="next">
                      <Button
                        disabled={page === pdfDocument.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        Next
                  </Button>
                    </li>
                  </Lists>
                </nav>
              )}
            </>
          )}
        </Pdf>
      </Modal>
      {textEnd}
    </>
  )
}

const mapStateToProps = state => ({
  termsOfService: state.owner.termsOfService
});

export default connect(mapStateToProps)(ModalTermsOfService);