import React from 'react';

import { Icon } from 'semantic-ui-react';

import styled from 'styled-components';

const PointerIcon = styled(Icon)`
  cursor: pointer;
`;

const IconButton = (props) => (
  <>
    <PointerIcon {...props} />
  </>
);

export default IconButton;
