import React from 'react';

const PageNotFound = () => (
  <>
    <div>
      <h1>Ops!</h1>
      <span>A página solicitada não foi encontrada!</span>
    </div>
  </>
);

export default PageNotFound;
