export const banks = [
  {
    "key": "001",
    "value": "001",
    "text": "001 - Banco do Brasil S.A."
  },
  {
    "key": "104",
    "value": "104",
    "text": "104 - Caixa Econômica Federal"
  },
  {
    "key": "237",
    "value": "237",
    "text": "237 - Banco Bradesco S.A."
  },
  {
    "key": "341",
    "value": "341",
    "text": "341 - Itaú Unibanco S.A."
  },
  {
    "key": "033",
    "value": "033",
    "text": "033 - Banco Santander (Brasil) S.A."
  },
  {
    "key": "077",
    "value": "077",
    "text": "077 - Banco Intermedium S.A."
  },
  {
    "key": "218",
    "value": "218",
    "text": "218 - Banco Bonsucesso S.A."
  },
  {
    "key": "477",
    "value": "477",
    "text": "477 - Citibank N.A."
  },
  {
    "key": "654",
    "value": "654",
    "text": "654 - Banco A.J.Renner S.A."
  },
  {
    "key": "246",
    "value": "246",
    "text": "246 - Banco ABC Brasil S.A."
  },
  {
    "key": "025",
    "value": "025",
    "text": "025 - Banco Alfa S.A."
  },
  {
    "key": "641",
    "value": "641",
    "text": "641 - Banco Alvorada S.A."
  },
  {
    "key": "213",
    "value": "213",
    "text": "213 - Banco Arbi S.A."
  },
  {
    "key": "019",
    "value": "019",
    "text": "019 - Banco Azteca do Brasil S.A."
  },
  {
    "key": "029",
    "value": "029",
    "text": "029 - Banco Banerj S.A."
  },
  {
    "key": "000",
    "value": "000",
    "text": "000 - Banco Bankpar S.A."
  },
  {
    "key": "740",
    "value": "740",
    "text": "740 - Banco Barclays S.A."
  },
  {
    "key": "107",
    "value": "107",
    "text": "107 - Banco BBM S.A."
  },
  {
    "key": "031",
    "value": "031",
    "text": "031 - Banco Beg S.A."
  },
  {
    "key": "739",
    "value": "739",
    "text": "739 - Banco BGN S.A."
  },
  {
    "key": "096",
    "value": "096",
    "text": "096 - Banco BM&F de Serviços de Liquidação e Custódia S.A"
  },
  {
    "key": "318",
    "value": "318",
    "text": "318 - Banco BMG S.A."
  },
  {
    "key": "752",
    "value": "752",
    "text": "752 - Banco BNP Paribas Brasil S.A."
  },
  {
    "key": "248",
    "value": "248",
    "text": "248 - Banco Boavista Interatlântico S.A."
  },
  {
    "key": "065",
    "value": "065",
    "text": "065 - Banco Bracce S.A."
  },
  {
    "key": "036",
    "value": "036",
    "text": "036 - Banco Bradesco BBI S.A."
  },
  {
    "key": "204",
    "value": "204",
    "text": "204 - Banco Bradesco Cartões S.A."
  },
  {
    "key": "394",
    "value": "394",
    "text": "394 - Banco Bradesco Financiamentos S.A."
  },
  {
    "key": "225",
    "value": "225",
    "text": "225 - Banco Brascan S.A."
  },
  {
    "key": "M15",
    "value": "M15",
    "text": "M15 - Banco BRJ S.A."
  },
  {
    "key": "208",
    "value": "208",
    "text": "208 - Banco BTG Pactual S.A."
  },
  {
    "key": "044",
    "value": "044",
    "text": "044 - Banco BVA S.A."
  },
  {
    "key": "263",
    "value": "263",
    "text": "263 - Banco Cacique S.A."
  },
  {
    "key": "473",
    "value": "473",
    "text": "473 - Banco Caixa Geral - Brasil S.A."
  },
  {
    "key": "412",
    "value": "412",
    "text": "412 - Banco Capital S.A."
  },
  {
    "key": "040",
    "value": "040",
    "text": "040 - Banco Cargill S.A."
  },
  {
    "key": "745",
    "value": "745",
    "text": "745 - Banco Citibank S.A."
  },
  {
    "key": "M08",
    "value": "M08",
    "text": "M08 - Banco Citicard S.A."
  },
  {
    "key": "241",
    "value": "241",
    "text": "241 - Banco Clássico S.A."
  },
  {
    "key": "M19",
    "value": "M19",
    "text": "M19 - Banco CNH Capital S.A."
  },
  {
    "key": "215",
    "value": "215",
    "text": "215 - Banco Comercial e de Investimento Sudameris S.A."
  },
  {
    "key": "756",
    "value": "756",
    "text": "756 - Sistema de Cooperativas de Crédito do Brasil - SICOOB"
  },
  {
    "key": "748",
    "value": "748",
    "text": "748 - Banco Cooperativo Sicredi S.A."
  },
  {
    "key": "075",
    "value": "075",
    "text": "075 - Banco CR2 S.A."
  },
  {
    "key": "721",
    "value": "721",
    "text": "721 - Banco Credibel S.A."
  },
  {
    "key": "222",
    "value": "222",
    "text": "222 - Banco Credit Agricole Brasil S.A."
  },
  {
    "key": "505",
    "value": "505",
    "text": "505 - Banco Credit Suisse (Brasil) S.A."
  },
  {
    "key": "229",
    "value": "229",
    "text": "229 - Banco Cruzeiro do Sul S.A."
  },
  {
    "key": "266",
    "value": "266",
    "text": "266 - Banco Cédula S.A."
  },
  {
    "key": "003",
    "value": "003",
    "text": "003 - Banco da Amazônia S.A."
  },
  {
    "key": "083-3",
    "value": "083-3",
    "text": "083-3 - Banco da China Brasil S.A."
  },
  {
    "key": "M21",
    "value": "M21",
    "text": "M21 - Banco Daimlerchrysler S.A."
  },
  {
    "key": "707",
    "value": "707",
    "text": "707 - Banco Daycoval S.A."
  },
  {
    "key": "300",
    "value": "300",
    "text": "300 - Banco de La Nacion Argentina"
  },
  {
    "key": "495",
    "value": "495",
    "text": "495 - Banco de La Provincia de Buenos Aires"
  },
  {
    "key": "494",
    "value": "494",
    "text": "494 - Banco de La Republica Oriental del Uruguay"
  },
  {
    "key": "M06",
    "value": "M06",
    "text": "M06 - Banco de Lage Landen Brasil S.A."
  },
  {
    "key": "024",
    "value": "024",
    "text": "024 - Banco de Pernambuco S.A. - BANDEPE"
  },
  {
    "key": "456",
    "value": "456",
    "text": "456 - Banco de Tokyo-Mitsubishi UFJ Brasil S.A."
  },
  {
    "key": "214",
    "value": "214",
    "text": "214 - Banco Dibens S.A."
  },
  {
    "key": "047",
    "value": "047",
    "text": "047 - Banco do Estado de Sergipe S.A."
  },
  {
    "key": "037",
    "value": "037",
    "text": "037 - Banco do Estado do Pará S.A."
  },
  {
    "key": "039",
    "value": "039",
    "text": "039 - Banco do Estado do Piauí S.A. - BEP"
  },
  {
    "key": "041",
    "value": "041",
    "text": "041 - Banco do Estado do Rio Grande do Sul S.A."
  },
  {
    "key": "004",
    "value": "004",
    "text": "004 - Banco do Nordeste do Brasil S.A."
  },
  {
    "key": "265",
    "value": "265",
    "text": "265 - Banco Fator S.A."
  },
  {
    "key": "M03",
    "value": "M03",
    "text": "M03 - Banco Fiat S.A."
  },
  {
    "key": "224",
    "value": "224",
    "text": "224 - Banco Fibra S.A."
  },
  {
    "key": "626",
    "value": "626",
    "text": "626 - Banco Ficsa S.A."
  },
  {
    "key": "M18",
    "value": "M18",
    "text": "M18 - Banco Ford S.A."
  },
  {
    "key": "233",
    "value": "233",
    "text": "233 - Banco GE Capital S.A."
  },
  {
    "key": "734",
    "value": "734",
    "text": "734 - Banco Gerdau S.A."
  },
  {
    "key": "M07",
    "value": "M07",
    "text": "M07 - Banco GMAC S.A."
  },
  {
    "key": "612",
    "value": "612",
    "text": "612 - Banco Guanabara S.A."
  },
  {
    "key": "M22",
    "value": "M22",
    "text": "M22 - Banco Honda S.A."
  },
  {
    "key": "063",
    "value": "063",
    "text": "063 - Banco Ibi S.A. Banco Múltiplo"
  },
  {
    "key": "M11",
    "value": "M11",
    "text": "M11 - Banco IBM S.A."
  },
  {
    "key": "604",
    "value": "604",
    "text": "604 - Banco Industrial do Brasil S.A."
  },
  {
    "key": "320",
    "value": "320",
    "text": "320 - Banco Industrial e Comercial S.A."
  },
  {
    "key": "653",
    "value": "653",
    "text": "653 - Banco Indusval S.A."
  },
  {
    "key": "630",
    "value": "630",
    "text": "630 - Banco Intercap S.A."
  },
  {
    "key": "249",
    "value": "249",
    "text": "249 - Banco Investcred Unibanco S.A."
  },
  {
    "key": "M09",
    "value": "M09",
    "text": "M09 - Banco Itaucred Financiamentos S.A."
  },
  {
    "key": "184",
    "value": "184",
    "text": "184 - Banco Itaú BBA S.A."
  },
  {
    "key": "479",
    "value": "479",
    "text": "479 - Banco ItaúBank S.A"
  },
  {
    "key": "376",
    "value": "376",
    "text": "376 - Banco J. P. Morgan S.A."
  },
  {
    "key": "074",
    "value": "074",
    "text": "074 - Banco J. Safra S.A."
  },
  {
    "key": "217",
    "value": "217",
    "text": "217 - Banco John Deere S.A."
  },
  {
    "key": "076",
    "value": "076",
    "text": "076 - Banco KDB S.A."
  },
  {
    "key": "757",
    "value": "757",
    "text": "757 - Banco KEB do Brasil S.A."
  },
  {
    "key": "600",
    "value": "600",
    "text": "600 - Banco Luso Brasileiro S.A."
  },
  {
    "key": "212",
    "value": "212",
    "text": "212 - Banco Matone S.A."
  },
  {
    "key": "M12",
    "value": "M12",
    "text": "M12 - Banco Maxinvest S.A."
  },
  {
    "key": "389",
    "value": "389",
    "text": "389 - Banco Mercantil do Brasil S.A."
  },
  {
    "key": "746",
    "value": "746",
    "text": "746 - Banco Modal S.A."
  },
  {
    "key": "M10",
    "value": "M10",
    "text": "M10 - Banco Moneo S.A."
  },
  {
    "key": "738",
    "value": "738",
    "text": "738 - Banco Morada S.A."
  },
  {
    "key": "066",
    "value": "066",
    "text": "066 - Banco Morgan Stanley S.A."
  },
  {
    "key": "243",
    "value": "243",
    "text": "243 - Banco Máxima S.A."
  },
  {
    "key": "045",
    "value": "045",
    "text": "045 - Banco Opportunity S.A."
  },
  {
    "key": "M17",
    "value": "M17",
    "text": "M17 - Banco Ourinvest S.A."
  },
  {
    "key": "623",
    "value": "623",
    "text": "623 - Banco Panamericano S.A."
  },
  {
    "key": "611",
    "value": "611",
    "text": "611 - Banco Paulista S.A."
  },
  {
    "key": "613",
    "value": "613",
    "text": "613 - Banco Pecúnia S.A."
  },
  {
    "key": "094-2",
    "value": "094-2",
    "text": "094-2 - Banco Petra S.A."
  },
  {
    "key": "643",
    "value": "643",
    "text": "643 - Banco Pine S.A."
  },
  {
    "key": "724",
    "value": "724",
    "text": "724 - Banco Porto Seguro S.A."
  },
  {
    "key": "735",
    "value": "735",
    "text": "735 - Banco Pottencial S.A."
  },
  {
    "key": "638",
    "value": "638",
    "text": "638 - Banco Prosper S.A."
  },
  {
    "key": "M24",
    "value": "M24",
    "text": "M24 - Banco PSA Finance Brasil S.A."
  },
  {
    "key": "747",
    "value": "747",
    "text": "747 - Banco Rabobank International Brasil S.A."
  },
  {
    "key": "088-4",
    "value": "088-4",
    "text": "088-4 - Banco Randon S.A."
  },
  {
    "key": "356",
    "value": "356",
    "text": "356 - Banco Real S.A."
  },
  {
    "key": "633",
    "value": "633",
    "text": "633 - Banco Rendimento S.A."
  },
  {
    "key": "741",
    "value": "741",
    "text": "741 - Banco Ribeirão Preto S.A."
  },
  {
    "key": "M16",
    "value": "M16",
    "text": "M16 - Banco Rodobens S.A."
  },
  {
    "key": "072",
    "value": "072",
    "text": "072 - Banco Rural Mais S.A."
  },
  {
    "key": "453",
    "value": "453",
    "text": "453 - Banco Rural S.A."
  },
  {
    "key": "422",
    "value": "422",
    "text": "422 - Banco Safra S.A."
  },
  {
    "key": "250",
    "value": "250",
    "text": "250 - Banco Schahin S.A."
  },
  {
    "key": "743",
    "value": "743",
    "text": "743 - Banco Semear S.A."
  },
  {
    "key": "749",
    "value": "749",
    "text": "749 - Banco Simples S.A."
  },
  {
    "key": "366",
    "value": "366",
    "text": "366 - Banco Société Générale Brasil S.A."
  },
  {
    "key": "637",
    "value": "637",
    "text": "637 - Banco Sofisa S.A."
  },
  {
    "key": "012",
    "value": "012",
    "text": "012 - Banco Standard de Investimentos S.A."
  },
  {
    "key": "464",
    "value": "464",
    "text": "464 - Banco Sumitomo Mitsui Brasileiro S.A."
  },
  {
    "key": "082-5",
    "value": "082-5",
    "text": "082-5 - Banco Topázio S.A."
  },
  {
    "key": "M20",
    "value": "M20",
    "text": "M20 - Banco Toyota do Brasil S.A."
  },
  {
    "key": "M13",
    "value": "M13",
    "text": "M13 - Banco Tricury S.A."
  },
  {
    "key": "634",
    "value": "634",
    "text": "634 - Banco Triângulo S.A."
  },
  {
    "key": "136",
    "value": "136",
    "text": "136 - Banco Unicred"
  },
  {
    "key": "M14",
    "value": "M14",
    "text": "M14 - Banco Volkswagen S.A."
  },
  {
    "key": "M23",
    "value": "M23",
    "text": "M23 - Banco Volvo (Brasil) S.A."
  },
  {
    "key": "655",
    "value": "655",
    "text": "655 - Banco Votorantim S.A."
  },
  {
    "key": "610",
    "value": "610",
    "text": "610 - Banco VR S.A."
  },
  {
    "key": "370",
    "value": "370",
    "text": "370 - Banco WestLB do Brasil S.A."
  },
  {
    "key": "021",
    "value": "021",
    "text": "021 - BANESTES S.A. Banco do Estado do Espírito Santo"
  },
  {
    "key": "719",
    "value": "719",
    "text": "719 - Banif-Banco Internacional do Funchal (Brasil)S.A."
  },
  {
    "key": "755",
    "value": "755",
    "text": "755 - Bank of America Merrill Lynch Banco Múltiplo S.A."
  },
  {
    "key": "744",
    "value": "744",
    "text": "744 - BankBoston N.A."
  },
  {
    "key": "073",
    "value": "073",
    "text": "073 - BB Banco Popular do Brasil S.A."
  },
  {
    "key": "078",
    "value": "078",
    "text": "078 - BES Investimento do Brasil S.A.-Banco de Investimento"
  },
  {
    "key": "069",
    "value": "069",
    "text": "069 - BPN Brasil Banco Múltiplo S.A."
  },
  {
    "key": "070",
    "value": "070",
    "text": "070 - BRB - Banco de Brasília S.A."
  },
  {
    "key": "092-2",
    "value": "092-2",
    "text": "092-2 - Brickell S.A. Crédito, financiamento e Investimento"
  },
  {
    "key": "081-7",
    "value": "081-7",
    "text": "081-7 - Concórdia Banco S.A."
  },
  {
    "key": "097-3",
    "value": "097-3",
    "text": "097-3 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda."
  },
  {
    "key": "085-x",
    "value": "085-x",
    "text": "085-x - Cooperativa Central de Crédito Urbano-CECRED"
  },
  {
    "key": "099-x",
    "value": "099-x",
    "text": "099-x - Cooperativa Central de Economia e Credito Mutuo das Unicreds"
  },
  {
    "key": "090-2",
    "value": "090-2",
    "text": "090-2 - Cooperativa Central de Economia e Crédito Mutuo das Unicreds"
  },
  {
    "key": "089-2",
    "value": "089-2",
    "text": "089-2 - Cooperativa de Crédito Rural da Região de Mogiana"
  },
  {
    "key": "087-6",
    "value": "087-6",
    "text": "087-6 - Cooperativa Unicred Central Santa Catarina"
  },
  {
    "key": "098-1",
    "value": "098-1",
    "text": "098-1 - Credicorol Cooperativa de Crédito Rural"
  },
  {
    "key": "487",
    "value": "487",
    "text": "487 - Deutsche Bank S.A. - Banco Alemão"
  },
  {
    "key": "751",
    "value": "751",
    "text": "751 - Dresdner Bank Brasil S.A. - Banco Múltiplo"
  },
  {
    "key": "064",
    "value": "064",
    "text": "064 - Goldman Sachs do Brasil Banco Múltiplo S.A."
  },
  {
    "key": "062",
    "value": "062",
    "text": "062 - Hipercard Banco Múltiplo S.A."
  },
  {
    "key": "399",
    "value": "399",
    "text": "399 - HSBC Bank Brasil S.A. - Banco Múltiplo"
  },
  {
    "key": "168",
    "value": "168",
    "text": "168 - HSBC Finance (Brasil) S.A. - Banco Múltiplo"
  },
  {
    "key": "492",
    "value": "492",
    "text": "492 - ING Bank N.V."
  },
  {
    "key": "652",
    "value": "652",
    "text": "652 - Itaú Unibanco Holding S.A."
  },
  {
    "key": "079",
    "value": "079",
    "text": "079 - JBS Banco S.A."
  },
  {
    "key": "488",
    "value": "488",
    "text": "488 - JPMorgan Chase Bank"
  },
  {
    "key": "014",
    "value": "014",
    "text": "014 - Natixis Brasil S.A. Banco Múltiplo"
  },
  {
    "key": "753",
    "value": "753",
    "text": "753 - NBC Bank Brasil S.A. - Banco Múltiplo"
  },
  {
    "key": "086-8",
    "value": "086-8",
    "text": "086-8 - OBOE Crédito Financiamento e Investimento S.A."
  },
  {
    "key": "254",
    "value": "254",
    "text": "254 - Paraná Banco S.A."
  },
  {
    "key": "409",
    "value": "409",
    "text": "409 - UNIBANCO - União de Bancos Brasileiros S.A."
  },
  {
    "key": "230",
    "value": "230",
    "text": "230 - Unicard Banco Múltiplo S.A."
  },
  {
    "key": "091-4",
    "value": "091-4",
    "text": "091-4 - Unicred Central do Rio Grande do Sul"
  },
  {
    "key": "084",
    "value": "084",
    "text": "084 - Unicred Norte do Paraná"
  }
];

export const accounts = [
  {
    "key": "C",
    "value": "C",
    "text": "Conta Corrente"
  },
  {
    "key": "P",
    "value": "P",
    "text": "Conta Poupança"
  }
];
