export const states = [
  {
    "key": "AC",
    "value": "AC",
    "text": "AC"
  },
  {
    "key": "AL",
    "value": "AL",
    "text": "AL"
  },
  {
    "key": "AP",
    "value": "AP",
    "text": "AP"
  },
  {
    "key": "AM",
    "value": "AM",
    "text": "AM"
  },
  {
    "key": "BA",
    "value": "BA",
    "text": "BA"
  },
  {
    "key": "CE",
    "value": "CE",
    "text": "CE"
  },
  {
    "key": "DF",
    "value": "DF",
    "text": "DF"
  },
  {
    "key": "ES",
    "value": "ES",
    "text": "ES"
  },
  {
    "key": "GO",
    "value": "GO",
    "text": "GO"
  },
  {
    "key": "MA",
    "value": "MA",
    "text": "MA"
  },
  {
    "key": "MT",
    "value": "MT",
    "text": "MT"
  },
  {
    "key": "MS",
    "value": "MS",
    "text": "MS"
  },
  {
    "key": "MG",
    "value": "MG",
    "text": "MG"
  },
  {
    "key": "PA",
    "value": "PA",
    "text": "PA"
  },
  {
    "key": "PB",
    "value": "PB",
    "text": "PB"
  },
  {
    "key": "PR",
    "value": "PR",
    "text": "PR"
  },
  {
    "key": "PE",
    "value": "PE",
    "text": "PE"
  },
  {
    "key": "PI",
    "value": "PI",
    "text": "PI"
  },
  {
    "key": "RJ",
    "value": "RJ",
    "text": "RJ"
  },
  {
    "key": "RN",
    "value": "RN",
    "text": "RN"
  },
  {
    "key": "RS",
    "value": "RS",
    "text": "RS"
  },
  {
    "key": "RO",
    "value": "RO",
    "text": "RO"
  },
  {
    "key": "RR",
    "value": "RR",
    "text": "RR"
  },
  {
    "key": "SC",
    "value": "SC",
    "text": "SC"
  },
  {
    "key": "SP",
    "value": "SP",
    "text": "SP"
  },
  {
    "key": "SE",
    "value": "SE",
    "text": "SE"
  },
  {
    "key": "TO",
    "value": "TO",
    "text": "TO"
  }
];
