import React from "react";
import styled from "styled-components";

import { Grid, Header, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { getPersistor } from "@rematch/persist";

import moment from "moment-timezone";
import "moment/min/locales";

import Card from "../../containers/Card";
import { successNotification } from "../../services/notification";

moment.locale("pt-br");

const Block = styled.div`
  padding: 1.3em;
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  margin: 0 0 1em 0;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const ConclusionForm = (props) => {
  const { dispatch, sale } = props;

  const handleFinish = () => {
    const persistor = getPersistor();

    dispatch({ type: "RESET" });

    persistor.purge();
    persistor.flush();

    props.history.push("/admin/billets");
  };

  return (
    <>
      {sale && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <ButtonWrapper>
                  <Button
                    primary
                    type="button"
                    floated="right"
                    onClick={handleFinish}
                  >
                    Concluir
                  </Button>
                </ButtonWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Card>
                  <Block>
                    <Grid.Column>
                      <TitleContainer>
                        <Header>Link gerado com sucesso</Header>
                      </TitleContainer>
                    </Grid.Column>
                    <Button
                      primary
                      type="button"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          sale?.checkout?.url ?? ""
                        );
                        successNotification("", "Link copiado.");
                      }}
                    >
                      Copiar Link
                    </Button>
                  </Block>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sale: state.sale,
});

export const Conclusion = connect(mapStateToProps)(ConclusionForm);
