import React, { useState, useRef } from "react";
import { Button } from "semantic-ui-react";
import styled from 'styled-components';

const StyleParagraph = styled.p`
  line-height: 20px;
  font-size: 12px;
`;

export const InputFile = (props) => {

  const [fileName, setFileName] = useState('');
  const [fileInputRef, setFileInputRef] = useState(useRef(''));

  return (
    <>
      <Button
        type="button"
        icon="file"
        content="Carregar documento"
        labelPosition="left"
        fluid
        onClick={(e) => {
          setFileInputRef(fileInputRef.current.click())
        }}
      />
      <input hidden
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          const file = e.target.files[0];
          const fileName = file.name || '';

          setFileName(fileName);
          props.changeFile(file);
        }}
      />
      <StyleParagraph>{fileName}</StyleParagraph>
    </>
  )
}

export default InputFile;
