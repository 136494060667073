import React from 'react';

import { ErrorMessage } from 'formik';
import { Divider, Grid, Header } from 'semantic-ui-react';

import styled from 'styled-components';
import InputFile from '../../components/InputFile';

const Error = styled(ErrorMessage)`
  padding: 0.5em 0 0 0;
  color: red;
`;

const FileUploadFields = (props) => {
  const { setFieldValue } = props;

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3'>
            CNS
          </Header>
          <Divider clearing />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column row={16}>
          <InputFile name="filename"
            changeFile={(filename) => setFieldValue('filename', filename)}
          />
          <Error name="filename" component="div" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FileUploadFields;
